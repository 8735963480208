/* button */

@mixin tab-style($type:main){
    @if $type == main {   
        .tab_tit{
            &:after{
                content:'';
                @include position(absolute,null,0,0,0);
                height:2px;
                background:$red;
            }
        } 
        .tit_lst{        
            display:flex;   
            position: relative;
        }
        .tit_item{
            position: relative;
            flex:none;
            width:auto;
            font-family:$pointFontFamily;
            background-color:#fcd8da;
            &:first-child{
                margin-left:auto;
            }
            &:last-child{
                margin-right:auto;
            }
            &.swiper-slide-thumb-active{   
                &:after{
                    content:'';
                    @include position(absolute,null,0,0,0);
                    z-index:1;
                    height:2px;
                    background:$white;
                } 
            }
            .tit_link{
                min-width:300px;
                padding-top:18px;
                font-size: 20px; 
                letter-spacing:-0.04em;
            }
        }
        @media screen and (max-width: 1279px) {
            .tit_item{
                &:first-child{
                    margin-left:0;
                }
                &:last-child{
                    margin-right:0;
                }
            }
        }
    }
    @if $type == sub {  
        .tab_tit{
            &:after{
                content:'';
                @include position(absolute,null,0,0,0);
                z-index:1;
                height:2px;
                background:$red;
            }
        } 
        .tit_lst{        
            display:flex;   
            position: relative;
        }
        .tit_item{
            //position: relative;
            flex:1;
            background-color:$light_pink;
            &:first-child{
                margin-left:auto;
            }
            &:last-child{
                margin-right:auto;
            }
            &[class*="active"]{   
                
                &.has_sub{
                    margin-bottom:60px;
                }
                .tit_link {
                    opacity: 1;
                    &:after{
                        content:'';
                        @include position(absolute,null,0,0,0);
                        z-index:2;
                        height:2px;
                        background:$white;     
                    }
                }
            }
            .tit_link{
                position: relative;
                padding-top: 21px;
                font-size: 18px;
                letter-spacing: -0.04em;
                opacity: .5;
            }
        }
        @media screen and (max-width: 1279px) {
            .tit_item{
                flex:none;
                width:auto;
                min-width:130px;                
                .tit_link{
                    padding-left:33px;
                    padding-right:33px;
                }
            }
        }
    }
}
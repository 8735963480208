body, input, select, textarea, button {
    font-family: "NanumSquareRound", "Apple SD Gothic Neo", sans-serif
}
.blind, caption span, legend {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    font-size: 1px;
    color: transparent;
    clip: rect(0 0 0 0);
}
.skip {
    height: 0
}
.skip a {
    display: block;
    @include position(absolute, -100px, null, null, 0);
    width: 100%;
    height: 25px;
    text-align: center
}
.skip a:focus, #skip a:active {
    @include position(absolute, 0, null, null, null);
    z-index: 100;
    padding: 10px 0;
    background: #000;
    font-weight: bold;
    color: $white;
    font-size: 20px;
    text-decoration: none
}

/* 공통 blank */
.is_mo,
.is_mo_sm{
    display:none;
}
.only_640,
.only_360{
    display:none;
}

/* icon */
.ico {
    position: relative;
    line-height: 1;
}
i {
    overflow: hidden;
    position: relative;
    display: inline-block;
    line-height: 999px;
    vertical-align: middle;
}

/*
    * layout
*/
.wrap {
    overflow: hidden;
    position: relative;
    max-width: 1920px;
    min-width: 320px;
    margin:0 auto;
}
header {
    position: relative;
    .inner {      
        position:relative; 
        width:100%;
        max-width: 1200px;
        margin: 0 auto;
        z-index: 10;       
        box-sizing: border-box;
    }
    .hd_top{
        position: relative;
        z-index:10;
        width:100%;
        background-color:white;
    }
    .logo {
        display:inline-block;
        padding: 27px 20px 26px;        
        a {
            display: block;
            @include sp-retina('logo_junior');
        }
    }    
    .nav_bar {
        @include position(absolute,0,0,null,404px);
        z-index:11;
        &:hover,
        &:focus {
            .menu_list{
                &:before{
                    z-index:11;
                    border-width:2px 0;
                }
            }
            .lnb_area {
                display: block
            }
        }
    }
    .menu_list {
        display:table;
        width:100%;
        font-size:0;
        table-layout:fixed;
        white-space: nowrap;     
        &:before{
            content:'';
            @include position(absolute, 100px, -200%, 0, -200%);
            z-index:-1;
            border:1px solid #ffc4c7;
            border-width:0;
            background-color:$pink;           
            
        }   
    }
    .menu_item {
        position: relative;
        display:table-cell;
        padding-top:40px;
        text-align:center;
        .menu_link {
            display:inline-block;
            position: relative;
            height:100%;
            margin-bottom:31px;
            padding-bottom:10px;
            color: $black;
            font-size: 17px;
            line-height: 19px;
            letter-spacing: -0.34px;
            font-weight: 400;
            box-sizing:border-box;  
            &:before {
                content: '';
                @include position(absolute,null,0,0,0);
                width:0%;
                height: 2px;
                background-color: $red;
            }
            &:hover {
                font-weight:800;
                color: $red;
                &:before {
                    width: 100%;
                    @include transition(width 0.2s ease-in-out);
                }
            }
            &.disabled{
                pointer-events:none;
                cursor:default;
            }
        }
        &.is_active {
            .menu_link {
                font-weight:800;
                color: $red;
                &:before {
                    width: 100%;
                }
            }
        }
    }
    .lnb_area {
        display:none;
        position: relative;
        width: 100%;
        padding: 22px 0 25px 0;
        z-index: 100;
        .sub_item {            
            display: block;
            margin-top:4px;
            line-height:28px;
            text-align: center;
            &:first-child{
                margin-top:0;
            }
            .sub_link {
                display: inline-block;
                position: relative;
                padding: 0 12px;
                font-size: 15px;
                font-weight:700;
                color: $red;
                letter-spacing:-.03em;      
                &:before{
                    content:'';
                    display:none;
                    @include position(absolute,0,0,null,0);    
                    z-index:-1;  
                    height:28px;             
                    border-radius: 14px;
                    background-color:$white;
                    line-height: 28px;
                }
                &:hover{
                    &:before{
                        display:block;
                    }
                }          
            }    
            &.is_active {
                .sub_link{                 
                    padding: 0 8px;
                    border-radius: 13px;
                    background-color:$white;
                    font-weight:600;
                }
            }
        }
        .detail_lst{
            display:none;
            padding: 5px 14px 0;
            li {
                a {
                    display:inline-block;
                    font-size: 14px;
                    color:$red;
                    line-height: 26px;
                    letter-spacing: -0.28px;
                }                
            }                
        }
    }
    .hd_bg{
        display:none;
        &.is_active{
            display:block;
            position:absolute;
            top:100px;
            left:0;
            right:0;
            bottom:0;
            z-index:10;
            width:100%;
            height:100%;
            border:1px solid #ffc4c7;
            border-width:2px 0;
            background-color:$pink;
        }
    }
    .btn_hamburger {
        display: none;
        @include position(absolute, 27px, 33px, null, null);
        z-index:11;
        overflow: visible;
        height: 42px;
        width: 39px;
        cursor: pointer;
        @include transition(opacity, filter 0.25s linear);
        text-transform: none;
        color: inherit;
        border: 0;
        background-color: transparent;
        outline: none;
        &:before {
            content: '';
            display: block;
            @include position(absolute, 15px, null, null, 0);
            width: 39px;
            height: 3px;
            @include transition(transform 0.25s ease);
            border-radius: 3px;
            background-color: $red;
        }
        &:after {
            content: '';
            display: block;
            @include position(absolute, 29px, null, null, 0);
            width: 39px;
            height: 3px;
            @include transition(transform 0.25s ease);
            border-radius: 3px;
            background-color: $red;
        }
    }
    .app_section {
        display: none;
        margin: 50px 0 35px;
        text-align: center;
        .app_des {
            font-size: 12px;
            line-height: 24px;
            letter-spacing: -0.24px;
            color: $black;
            margin-left: -15px;
            font-weight:700;
        }
        .btn_app_direct {
            position: relative;
            display: inline-block;
            min-width: 300px;
            padding: 13px 10px;
            @include btn-bg(red);
            border-radius:50px;
            font-weight:800;
            &:before{
                content:'';
                @include position(absolute, -46px, null, null, 7px);
                width:72px;
                height:52px;
                @include inline-svg-icon('ico_girl');
                background-size: 100% 100%;
            }
        }
    }
}
footer {
    background-color: #fdfdfd;
    border-top: 2px solid #f4f4f4;
    .inner {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        padding: 39px 0 52px;
        box-sizing: border-box;
    }
    .logo_tuntun {
        display: block;
        @include position(absolute, null, null, null, 40px);
        @include sp-normal('logo_tuntun');
    }
    .foot_menu {
        display: inline-block;
        margin: 2px 0 0 195px;
        font-size:0;
        vertical-align:top;
        .menu_item{
            display:inline-block;
            margin-left: 30px;
            vertical-align:middle;
            white-space: nowrap;
            &:first-child {
                margin-left: 0;
            }
        }
        .menu_link{
            display:block;
            color: $black;
            font-size: 13px;
            font-weight:600;
            line-height: 24px;
            letter-spacing: -0.26px;
            &:hover {
                color: $red;
            }
        }
    }
    .area_info {
        display: inline-block;
        margin: 16px 0 0 195px;
    }
    .address_lst {
        @include clearfix;
        .address_item {
            float:left;
            color: #707070;
            font-size: 12px;
            font-weight:600;
            letter-spacing: -0.24px;
            line-height: 20px;
            &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 10px;
                background: #707070;
                vertical-align: middle;
                margin: -1px 8px 0 6px;
            }
            &:nth-child(3){
                clear:both;
            }
            &:nth-child(2),
            &:last-child{
                &:after{
                    display:none;
                }
            }
        }
    }
    .copyright {
        margin-top: 30px;
        font-weight:800;
        font-size: 13px;
        line-height: 31px;
        letter-spacing: -0.26px;
        color: #707070;
    }
    .area_link {
        float: right;
        margin: -32px 100px 0 0;
        .tuntun_family {
            float: right;
            position: relative;
            margin-bottom: 20px;
            .tit_lst {
                width: 200px;
                height: 40px;
                border-radius: 30px;
                color: $black;
                font-size: 14px;
                font-weight:700;
                display: inline-block;
                letter-spacing: -0.3px;
                padding: 0 40px 0 55px;
                line-height: 40px;
                background-color: #f4f4f4;
                box-sizing: border-box;
                &:after{
                    content:'';
                    display:inline-block;
                    width:8px;
                    height:5px;
                    margin:-2px 0 0 8px;
                    vertical-align:middle;
                    background-image:inline-svg(arr2_up, $red);
                    background-size:100% 100%;
                }
            }
            .lst_link {
                display: none;
                @include position(absolute, null, null, 40px, 0);
                min-width: 200px;
                padding: 14px;
                background-color: $white;
                border: 1px solid #707070;
                z-index: 20;
                box-sizing: border-box;
                li {
                    a {
                        display: block;
                        color: $black;
                        font-size: 12px;
                        line-height: 2;
                    }
                    &:hover {
                        background-color: #f4f4f4;
                    }
                }
            }
            &.is_active {
                .tit_lst{
                    &:after{                        
                        transform: rotate(-180deg);
                    }
                }
                .lst_link{
                    display: block;
                }
            }
        }
    }
    .foot_sns {
        clear: both;
        .sns_lst {
            overflow: hidden;
            font-size:0;
            text-align:right;
        }
        .sns_item {
            display:inline-block;
            position: relative;
            margin-left: 8px;
            &:first-child{
                margin-left:0;
            }
            .sns_link{
                display: block;
                width: 40px;
                height: 40px;
                background-color: $gray;
                border-radius: 50%;
                &:after {
                    content: '';
                    @include aln;
                    background-size:100% 100%;
                }
                &.sns_plus {
                    &:after {
                        width:16px;
                        height:15px;
                        background-image:inline-svg("ico_pluskakao", $black);
                    }
                }
                &.sns_cafe {
                    &:after {
                        width:22px;
                        height:10px;
                        @include sp-retina('ico_cafe');
                    }
                }
                &.sns_blog {
                    &:after {
                        width:24px;
                        height:11px;
                        background-image:inline-svg("ico_blog", $black);
                    }
                }
                &.sns_youtube {
                    &:after {
                        width:20px;
                        height:14px;
                        background-image:inline-svg("ico_youtube", $black);
                    }
                }
                &.sns_kakao {
                    &:after {
                        width:20px;
                        height:19px;
                        background-image:inline-svg("ico_kakao", $black);
                    }
                }
                &.sns_facebook {
                    &:after {
                        width:8px;
                        height:15px;
                        background-image:inline-svg("ico_facebook", $black);
                    }
                }
                &.sns_tuntun {
                    font-family: $pointFontFamily;
                    font-size: 11px;
                    font-weight:600;
                    color:$black;
                    text-align:center;
                    line-height:40px;
                    letter-spacing: -0.2px;
                    &:after{
                        display:none;
                    }
                }
                &:hover,
                &:focus{
                    background-color: $black;
                    &.sns_plus {
                        &:after {
                            background-image:inline-svg("ico_pluskakao", $white);
                        }
                    }
                    &.sns_cafe {
                        &:after {
                            @include sp-retina('ico_cafe');
                        }
                    }
                    &.sns_blog {
                        &:after {
                            background-image:inline-svg("ico_blog", $white);
                        }
                    }
                    &.sns_youtube {
                        &:after {
                            background-image:inline-svg("ico_youtube", $white);
                        }
                    }
                    &.sns_kakao {
                        &:after {
                            background-image:inline-svg("ico_kakao", $white);
                        }
                    }
                    &.sns_facebook {
                        &:after {
                            background-image:inline-svg("ico_facebook", $white);
                        }
                    }
                    &.sns_tuntun {
                        color:$white;
                    }
                }
            }
        }
    }
}

/*
    * sub layout
*/
.sub_hd{
    @include position(fixed,-100%,0,null,0);
    z-index:-1;
    height:60px;
    padding-left:25px;
    background:$red;
    opacity:0;
    transition:all .2s ease;
    &:before{
        @include aln_m;
    }
    .page_tit{
        display: inline-block;    
        font-size: 22px;
        font-weight:800;
        letter-spacing: -0.4px;
        color:$white;
        vertical-align: middle;
    }
}
.top_content {
    position: relative;
    height: 210px;
    .inner {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }
    .h_tit{
        @include position(absolute, 68px, 0, null, 0);
        z-index:1;
        width:1200px;
        margin:0 auto;
        font-family: $pointFontFamily;
        font-size: 22px;
        font-weight:300;
        color:$red;
        letter-spacing: -0.04em;
        .point{
            display:block;
            margin-top: 8px;
            font-size:28px;
            font-weight:600;
            letter-spacing: -0.06em;
        }
        .line{
            position: relative;
            &:before{
                content: '';
                @include position(absolute, null, 0, -6px, 0);
                height:2px;
                background-color:$white;
            }
        }
    }
    .vdo{
        position: relative;
        height: 100%;
        video {
            @include position(absolute, 0, null, null, 50%);
            @include translateX(-50%);
            min-height: 100%;
            object-fit: cover;
        }
        img {
            @include position(absolute, 0, null, null, 0);
            z-index: -1;
            width: auto;
            min-width: 0;
            height: 100%;
            min-height: 0;
        }
    }
    .thumb{
        position: relative;
        height: 100%;
        background-color:$light_pink;
        img {
            @include position(absolute, 0, null, null, 50%);
            @include translateX(-50%);
            min-height: 100%;
        }
    }
}
.inner_wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 52px 0 159px;
    &.inner_type{
        max-width:1000px;
        padding-top:20px;
    }
}

/* 
    * common
*/
.point {
    font-weight: 600;
}
.paging {
    height: 6px;
    [class*="bullet"] {
        width: 20px;
        height: inherit;
        margin: 0 5px;
        border-radius: 3px;
        background-color: #ddd;
        vertical-align: top;
        opacity: 1;
        &:first-child {
            margin-left: 0;
        }
    }
    [class*="active"] {
        width: 44px;
        background-color: $red !important;
    }
}
.paging_num {
    position: static;
    display: inline-block;
    width: auto;
    font-size: 18px;
    font-weight: 800;
    color: $red;
    letter-spacing: -.04em;
    vertical-align: middle;
}
.paging_line{
    &.swiper-scrollbar{
        position: static;
        width:100%;
        height:2px;
        background-color:#e9e9e9;
        text-align: left;
        vertical-align:middle;
        transition: .3s background;
        opacity:1 !important;
        .swiper-scrollbar-drag{
            background-color:$red;
        }
    }
}
.btn_top {
    @include position(fixed, null, 0, 30px,50%);
    z-index: -1;
    width:60px;
    height:60px;
    margin-left:540px;
    border-radius:50%;
    background-color:#f4f4f4;
    opacity: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: all .1s ease-in;
    &:before {
        content: '';
        @include position(absolute,50%,null,null,50%);
        width:24px;
        height:14px;
        margin:-7px 0 0 -12px;
        border-radius: 50%;
        background-image:inline-svg(ico_top, $red);
        background-size:100% 100%;        
    }
    &.is_active {
        z-index: 1;
        opacity: 1;
    }
}
.link_bnr{   
    display:table;
    width:100%;
    height:105px;
    background-color:$red; 
    .inner{
        display:table-cell;
        position: relative;
        width:1200px;
        height: 100%;
        margin:0 auto;
        padding:0 140px;
        vertical-align:middle;
        text-align:center;
        box-sizing:border-box;
        &:before{
            content:'';
            @include position(absolute,-71px,0,null,0);
            width: 113px;
            height: 80px;
            margin: 0 auto;
            @include inline-svg-icon('ico_girl');
            background-size: 100% 100%;
        }
    }
    .desc{
        display:inline-block;
        font-size: 22px;
        font-weight:400;
        color:$white;
        letter-spacing: -.04em;
        vertical-align:middle;
    }
    .btn_link{
        display:inline-block;
        min-width:250px;
        height: 40px;
        margin-left:32px;
        padding-top: 9px;
        @include btn-border(white);
        border-radius: 20px;
        font-size: 18px;
        font-weight: 800;
        letter-spacing: -.04em;
    }
}

/* 
    * loading
*/
.loading {
    position: relative;
    width: 66px;
    height: 66px;
    div {
        transform-origin: 33px 33px;
        animation: loading-spiner .8s linear infinite;
        &:nth-child(1) {
            transform: rotate(0deg);
            animation-delay: -.7s;
        }
        &:nth-child(2) {
            transform: rotate(45deg);
            animation-delay: -.6s;
        }
        &:nth-child(3) {
            transform: rotate(90deg);
            animation-delay: -.5s;
        }
        &:nth-child(4) {
            transform: rotate(135deg);
            animation-delay: -.4s;
        }
        &:nth-child(5) {
            transform: rotate(180deg);
            animation-delay: -.3s;
        }
        &:nth-child(6) {
            transform: rotate(225deg);
            animation-delay: -.2s;
        }
        &:nth-child(7) {
            transform: rotate(270deg);
            animation-delay: -.1s;
        }
        &:nth-child(8) {
            transform: rotate(315deg);
            animation-delay: 0s;
        }
        &:after {
            content: '';
            @include position(absolute, 0, null, null, 28px);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $red;
        }
    }
}

/*
    * 이동경로
*/
.breadcrumbs_box{
    .breadcrumbs_lst{
        font-size:0;
        text-align:right;
    }
    .breadcrumbs_item{
        display:inline-block;
        &:last-child{
            .breadcrumbs_link{
                color:$red;
                font-weight:600;
                &:after{
                    display:none;
                }
            }
        }
    }
    .breadcrumbs_link{
        display:block;
        font-size:13px;
        font-weight:400;
        letter-spacing:-.026em;
        color:$black;
        &:after{
            content:'';
            display:inline-block;
            width:5px;
            height:8px;
            margin:0 10px;
            background-image:inline-svg(arr_path_rgt, #ddd);
            background-size: 100% 100%;
            vertical-align:middle;
        }
    }
}

/* 
    * scroll
*/
.mCSB_inside {
    >.mCSB_container {
        margin-right: 38px;
    }
}
.mCSB_scrollTools {
    top: -1px;
    right: -1px;
    bottom: 30px;
    width: 14px;
    border: 1px solid $gray;
    @include background-opacity($white, 1);
    opacity: 1;
    &:before {
        content: '';
    }
    .mCSB_draggerContainer {
        margin: 3px 0;
    }
    .mCSB_draggerRail {
        width: 14px;
        border-radius: 0;
        background-color: $white;
    }
    .mCSB_dragger {
        .mCSB_dragger_bar {
            width: 8px;
            border-radius: 4px;
            background-color: $gray;
        }
        &:hover {
            .mCSB_dragger_bar {
                background-color: $gray;
            }
        }
        &.mCSB_dragger_onDrag {
            .mCSB_dragger_bar {
                width: 8px;
                border-radius: 4px;
                background-color: $gray;
            }
        }
    }
}

/*
    * sub
*/
// 190611 회색박스 추가한 부분이에요
.main_option_lst {
    height: 80px;
    font-size: 0;
    background-color: #f4f4f4;
    border-radius: 40px;
    &.is_fixed {
        @include position(fixed, 0, 0, null, 0);
        z-index: 100;
        transition: all .3s ease;
    }
    .option_item {
        display: inline-block;
        position: relative;
        height: 100%;
        vertical-align: middle;
        &:before {
            @include aln_m;
        }                
        &.is_active {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background-color: $red;
                transition: all .3s ease;
            }
            a{
                color: $red;
                font-weight: 800;
            }
        }
    }
    a{
        display: inline-block;
        color: #707070;
        vertical-align: middle;
    }
}

/*
	* page_section
*/
.page_section{
	padding-top:36px;
	.page_tit{
		font-size:30px;
		font-family:$pointFontFamily;
		font-weight:700;
		color:$red;
		text-align:center;
        &.tit_lang{
            font-family:$defaultFontFamily;
        }
	}
	.page_desc{
		font-size:16px;
		line-height:1.5;
		color:#1a1311;
		font-weight:700;
		text-align:center;
		.point{
			color:$red;
		}
	}
}
 
/*
	* pagination
*/
.pagination{
	text-align:center;
	font-size:0;
	.prev{
		width:20px;
		height:20px;
		background-image:inline-svg(arr4_left, $red);
		background-size:5px 10px;
		background-position:center center;
		background-repeat:no-repeat;
	}
	.next{
		width:20px;
		height:20px;
		background-image:inline-svg(arr4_right, $red);
		background-size:5px 10px;
		background-position:center center;
		background-repeat:no-repeat;
	}
	.first{
		width:20px;
		height:20px;
		background-image:inline-svg(arr4_left_gr, $red);
		background-size:10px 10px;
		background-position:center center;
		background-repeat:no-repeat;
	}
	.last{
		width:20px;
		height:20px;
		background-image:inline-svg(arr4_right_gr, $red);
		background-size:10px 10px;
		background-position:center center;
		background-repeat:no-repeat;
	}
	.page_btn{
		display:inline-block;
		vertical-align:middle;
	}
	.page_num{
		display:inline-block;
		margin:0 20px;
		.page_btn{
			padding:0 5px;
			height:20px;
			font-size:16px;
			color:#1a1311;
			line-height:22px;
		}
		.is_active{
			color:$red;
			font-weight:700;
		}
	}
}

/*
	* select box
*/
.sel_area{
	font-size:0;
	text-align:center;
}
.sel{
    display:block;
    position:relative;
    font-size:16px;
    .select{
        display:block;
        width:100%;
        height:50px;
        border-radius:60px;
        background-color:#f4f4f4;
        box-sizing:border-box;
        color:#1a1311;
        text-align:center;
        transition:all 0.1s ease;
        &:before{
            @include aln-m;
        }
        &:after{
            position:absolute;
            right:42px;
            top:50%;
            width:32px;
            height:32px;
            background-image:inline-svg(arr4_down, $red);
            background-size:16px 8px;
            background-position:center;
            background-repeat:no-repeat;
            content:'';
            transform:translateY(-50%) rotate(0);
            transform-origin:center center;
        }
    }
    .sel_lst{
        display:none;
        overflow:hidden;
        @include position(absolute,100%,0,null,0);
        z-index:100;
        width:300px;
        max-height:300px;
        margin:0 auto;
        border:2px solid $red;
        background-color:#fff;
        box-sizing:border-box;        
        .sel_item{
            display:block;
            width:100%;
            height:43px;
            border-radius:60px;
            color:#1a1311;
            text-align:center;
            &:before{
                @include aln-m;
            }
            &:hover{
                background-color:#f4f4f4;
            }
        }
        .mCSB_inside{
            .mCSB_container{
                margin:0;
                padding:23px 40px;
            }
            .mCSB_scrollTools{
                bottom:-1px;
                border:0;
                background-color:transparent;
            }
        } 
    }
    &.is_active{
        .select{
            border:2px solid $red;
            background-color:transparent;
            &:after{
                transform:translateY(-50%) rotate(180deg);
            }
        }
        .sel_lst{
            display:block;
        }
    }
}

/*
	* input
*/
.inp_area{
	position:relative;
	.btn_empty{
		overflow:hidden;
		@include alnY;
		right:30px;
		width:21px;
		height:21px;
		border-radius:50%;
		background-color:$red;
		visibility:hidden;
        &:before{
            @include position(absolute,50%,0,null,0);
            width:11px;
            height:11px;
            margin:-6px auto 0;
            background-image:inline-svg(ico_cancel, $white);
            background-size:100% 100%;
            content:'';
        }
        &.on{
            visibility:visible;
        }
	}
	.inp{
		display:block;
		width:100%;
		height:60px;
		padding:0 60px 0 30px;
		border:2px solid transparent;
		border-radius:60px;
		box-sizing:border-box;
		background:#f4f4f4;
		font-size:17px;
        transition:all 0.4s ease;
		&:focus{
			outline:none;
			border-color:$red;
			background-color:$white;
			+ .btn_empty{
				visibility:visible;
			}
		}
		&::-ms-clear{
			display:none;
		}
        &.is_active{
            border:2px solid $red;
            background:#fff;
        }
		
	}
}

/*
 *	검색버튼 있는 input text
 */
.search_form{
	position:relative;
	display:inline-block;
	.inp_area{
		display:inline-block;
        vertical-align:middle;
	}
	.inp_address{
		width:450px;
		height:50px;
		padding:0 30px;
		&::placeholder{
			font-size:16px;
			color:#707070;
		}
	}
	.btn_search{
        display:inline-block;
		@include btn-border(red);
		width:90px;
		height:50px;
		margin-left:10px;
		border-radius:60px;
		font-size:16px;
		font-weight:800;
        vertical-align:middle;
	}
}

/*
	* checkbox_area
*/

.checkbox_area{
	position:relative;
	input[type="checkbox"]{
		position:absolute;
		top:0;
		left:-9999px;
		overflow:hidden;
		width:0;
		height:0;
		&:checked + .lab_chx{
			&:before{
				background-color:$red;
				background-image:inline-svg(ico_checked, $white);
				background-size:17px 13px;
				background-repeat:no-repeat;
				background-position:center;
			}
		}
	}
	.lab_chx{
		display:inline-block;
		position:relative;
		padding-left:34px;
		font-weight:700;
		cursor:pointer;
		&:before{
			position:absolute;
			top:50%;
			left:0;
			width:24px;
			height:24px;
			box-sizing:border-box;
			border:2px solid $red;
			border-radius:50%;
			background-color:#fff;
			transform:translateY(-50%);
			vertical-align:middle;
			content:'';
		}
	}
    .required{
        @include position(absolute,50%,null,null,100%);
        min-width:28px;
        height:16px;
        margin:-8px 0 0 4px;
        padding:2px 6px 0;
        border-radius:8px;
        background-color:$white;
        font-size:10px;
        font-weight: 700;
        color:$red;
        letter-spacing: -.02em;
        white-space: nowrap;
        box-sizing: border-box;
    }
    .mCSB_inside{
        .mCSB_container{
            margin-right:0;
            padding:20px 29px 15px 20px;
        }
        .mCSB_scrollTools{
            bottom:-1px;
            border:0;
            background-color:transparent;
        }
    }
}

/*
	* tab area
*/
.tab_area{
	@include tab-style(sub);
}

/*
	* dimmed
*/
.dimmed{
	display:none;
	@include position(fixed, 0, 0, 0, 0);
	background-color:rgba(0,0,0,0.5);
    &.is_active{
        display:block;
        z-index:10;
    }
}

/*
	* navi box
*/
.navi_box {
    min-height:70px;
    .loading{
        display:none;
        @include position(absolute,8px,null,null,0);
        z-index:-1;
    }
    a{
        margin-top:-8px;
        &:hover{
            ~ .loading{
                display:block;
            }
        }
        &[class*="disabled"]{
            opacity:.2;
        }
    }
    .btn_prev {
		background-image:inline-svg(arr3_left, $red);
		background-size: 100% 100%;
		&:hover{
			~ .loading{
				left:0;
				right:auto;
			}
		}
	}
	.btn_next {
		background-image:inline-svg(arr3_right, $red);
		background-size: 100% 100%;
		&:hover{
			+ .loading{
				left:auto;
				right:0;
			}
		}
    }
    
    &.navi_text_box {
        a > span {
            display: block;
            margin-top: -10px;
            font-size: 12px;
            font-weight: 800;
            line-height: 2;
            letter-spacing: -0.2px;
            color: $red;
            box-sizing: border-box;
        }
        .btn_prev {
            span {
                padding-right: 5px;
                padding-left: 25px;
                text-align: right;
            }
        }
        .btn_next {
            span {
                padding-right: 25px;
                padding-left: 5px;
                text-align: left;
            }
        }
    }
}

/*
    * keyframes
*/
@include keyframes(loading-spiner) {
    0% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.less_than_640{
	display:none;
}

/* 
    * 1921px ~
*/
@media screen and (min-width: 1921px){
    .wrap{
        max-width:none;        
    }
}

/* 
    * ~ 1279px
*/
@media screen and (max-width: 1279px) {
    /* blank */
    .is_pc{
        display:none;
    }
    .is_mo {
        display: block;
    }
    .only_1280{
        display:none;
    }
    .only_640{
        display:block;
    }
    .btn_top {
        left:auto;
        right:0;
        margin-left:0;
    }
    .paging_num {
        height: 18px;
        padding: 3px 8px;
        border-radius: 9px;
        background-color: #fceaeb;
        font-size: 12px;
        font-weight: 800;
        text-align: center;
        letter-spacing: -.02em;
        box-sizing: border-box;
    }
    .wrap {
        &.is_fixed{
            .sub_hd{
                top:0;
                z-index:15;
                opacity: 1;
                
            }
            .btn_hamburger{
                position:fixed;
                top:9px;
                right:30px;
                z-index:12;
                &:before,
                &:after{
                    background-color:$white;
                }
            }
            .nav_bar{
                top:60px;
            }
            header {
                z-index: 20;
                &.is_active {
                    .btn_hamburger {
                        &:before,
                        &:after {
                            background-color: $red;
                        }
                    }
                }
            }
        }
    }
    header {
        .nav_bar {
            position: static;
            width: 100%;
            height:0;
            z-index:-1;
            text-align: left;
            background-color: $white;
            overflow-x: hidden;
            overflow-y: auto;   
            &:hover,
            &:focus {
                .lnb_area {
                    display: none;
                }
                .menu_list{
                    &:before{
                        display:none;
                    }
                }
            }
        }
        .btn_hamburger {
            display: block
        }
        .menu_list{
            border-top: 1px solid #f4f4f4;
            &:before{
                display:none;
            }
        }
        .menu_item {
            display: block;
            width:auto;
            padding-top:0;
            border-bottom: 1px solid #f4f4f4;
            text-align:left;
            .menu_link {      
                height:50px; 
                margin-bottom:0;             
                padding:0 37px;
                font-size:20px;
                font-weight:600 !important;
                color:$red;
                line-height:50px;
                letter-spacing: -0.4px;              
                &:before{
                    display:none;
                } 
                &:not(.has_lst){
                    &:after{
                        display:none;
                    }
                    + .lnb_area{
                        display:none !important;
                    }
                }               
            }
            .has_lst{
                &:after {
                    content: '';                    
                    display:inline-block;
                    width:10px;
                    height:6px;
                    margin-left:10px;
                    background-image:inline-svg(arr2_up, $red);
                    background-size: 100% 100%;
                    vertical-align:middle;
                    transform: rotateX(-180deg);
                }                
            }            
            &.is_active {
                .menu_link{
                    &.has_lst{
                        &:after{
                            transform: rotateX(0);
                        }
                    }
                }
                .lnb_area {
                    display: block;
                    position:relative;
                    background-color: $pink;
                    border-top: 2px solid #ffc4c7;
                    border-bottom: 2px solid #ffc4c7;
                }
            }
        }
        
        .lnb_area {
            padding:8px 37px 4px;
            border-bottom:1px solid #f4f4f4;  
            .detail_lst {
                padding: 7px 14px;
                li{
                    &.is_active {
                        a{             
                            padding: 0 8px;
                            border-radius: 13px;
                            background-color:$white;
                            font-weight:600;
                        }
                    }
                }
            }   
            .sub_item {
                margin-top:10px;    
                text-align:left;  
                .sub_link{
                    padding:0;
                    &:hover{
                        &:before{
                            display:none;
                        }
                    }
                }
                &.is_active {
                    .sub_link{
                        &.has_lst{
                            padding:0;
                            border-radius:0;
                            background:none;
                            &:after{
                                transform: rotateX(0);
                            }
                        }
                    }
                    .detail_lst{
                        display:block;
                    }
                }          
            } 
        }        
        .app_section {
            display: block;
        }
        &.is_active {  
            .inner{
                @include position(fixed, 0,0,null,0);
                z-index:100;
                height:100%;
                background-color:$white
            }          
            .nav_bar {
                position: static;
                z-index: 10;
                bottom:0;         
                min-height:100%;       
            }
            .btn_hamburger {
                &:before {
                    -webkit-transform: translate3d(0, 5px, 0) rotate(135deg);
                    transform: translate3d(0, 5px, 0) rotate(135deg);
                }
                &:after {
                    top: 25px;
                    -webkit-transition: top.1s, -webkit-transform .1s .1s;
                    transition: top.1s, transform .1s .1s;
                    -webkit-transform: translate3d(0, -5px, 0) rotate(45deg);
                    transform: translate3d(0, -5px, 0) rotate(45deg);
                }
            }    
        }
    }
	.sel_area{
		.sel{
			.sel_lst{
				position:fixed;
				top:50%;
				left:50%;
				z-index:100;
				transform:translate(-50%,-50%);
			}
		}
	}
	
    footer {
        .inner {
            width: auto;
            padding: 0 0 56px;
        }
        .logo_tuntun {
            display: none;
        }
        .foot_menu {
            display: block;
            height:72px;
            margin-left: 0;
            padding:0 30px;
            border-bottom: 2px solid #f4f4f4;
            box-sizing:border-box;
            &:before{
                @include aln_m;
            }
            .menu_item{
                margin-left:16px;
                .menu_link{
                    font-size: 14px;
                }
            }
        }
        .area_info {
            margin: 0;
            padding:24px 0 0 30px;
            .address_lst {
                .address_item {
                    display:block;
                    float:none;
                    &:after {
                        display: none;
                    }
                }
            }
            .copyright {
                margin-top: 16px;
            }
        }
        .area_link {
            margin:0;
            padding:24px 30px 0 0;
            .tuntun_family {
                margin-bottom: 15px;
                .tit_lst {
                    width: 260px;
                    height: 50px;
                    padding: 0 80px 0 81px;
                    font-weight:800;
                    line-height: 50px;
                }
                .lst_link {
                    min-width: 260px;
                    bottom: 50px;
                }
            }
        }
    }
    .link_bnr{
        height:116px;
        .inner{
            width:100%;
            padding:0;
            text-align:center;
            &:after{
                display:none;
            }
        }
        .desc{
            display:block;
            font-size:16px;
        }
        .btn_link{
            margin:11px 0 0 0;
        }
    }
	
	.page_section{
        padding-top:20px;
		.page_desc{
			font-size:14px;
		}
	}
	.pagination{
		display:none;
	}
	.accept{
		.checkbox_area{
			.sub_agree{
				.sub_agree_tit{
					.btn_more{
						right:9px;
					}
				} 
			}
		}
	}   
	.inp_area{
		.btn_empty{
			right:20px;
		}
		.inp{
			padding:0 50px 0 30px;
            font-size:15px;
		}
	}
	.inner_wrap {
		width: 100%;
		padding: 0 0 113px;
	}
	.top_content, .breadcrumbs_box{
		display: none;
    }
}

/* 
    * ~ 639px
*/
@media screen and (max-width: 639px) {
	.more_than_640{
		display:none;
	}
	.less_than_640{
		display:block;
	}
    .is_pc,
    .is_wide {
        display: none !important;
    }
    .is_tablet{
        display: none;
    }
    .is_mo_sm,
    .only_360 {
        display: block;
    }
    .only_1280,
    .only_640{
        display:none;
    }
    header {
        h1 {
            padding: 27px 30px 26px;
        }
        .nav_bar{
            &.is_active{
                &:after{
                    display:none;
                }
            }
        }
    }
    footer {
        border-top: 1px solid $gray;
        .inner {
            min-width: 320px;
            padding: 0 0 43px 0;
        }
        .foot_menu {
            display:table;
            width:100%;
            height:60px;
            margin-left:0;
            padding: 15px 30px;
            border-bottom: 1px solid $gray;
            &:before{
                display:none;
            }
            .menu_item{
                display:table-cell;
                padding-left:10px;
                text-align:center;
                &:first-child{
                    padding-left:0;
                }
            }
            .menu_link{
                font-size: 12px;
            }
        }
        .area_info {
            display: block;
            margin-top: 25px;
            padding:0;
            text-align: center;
            .copyright {
                margin-top: 15px;
            }
        }
        .area_link {
            float: none;
            margin: 31px 0 0 0;
            padding:0;
            text-align: center;
            .tuntun_family {
                float: none;
                margin-bottom: 30px;
                .lst_link {
                    left: calc(50% - 130px);
                }
            }
        }
        .foot_sns{
            .sns_lst{
                text-align:inherit;
            }
        }
    }
    .link_bnr{
        height:160px;
        .desc{
            font-size:18px;
            line-height: 1.33;
            letter-spacing: -0.4px;
        }
        .btn_link{
            margin-top:16px;
        }
    }
	.search_form{
		width:100%;
		.inp_area{
			width:100%;
			.btn_empty{
				right:50px;
			}
		}
		.inp_address{
			display:block;
			width:100%;
            padding-right:50px;
            padding-left:15px;
		}
		.btn_search{
			overflow:hidden;
			position:absolute;
			top:50%;
			right:0;
			width:50px;
            height:50px;
            margin: 0;
			border-radius:0;
            border:0;
            background-repeat: no-repeat;
			background-color:transparent;
			background-image:inline-svg(ico_search, $red);
            background-size: 20px auto;
            background-position: 50% 50%;
			text-indent:-9999px;
			transform:translateY(-50%);
			&:hover,&:focus{
				background-color:transparent;
			}
		}
		.address_inp{
			width:100%;
		}
	}
}

/* 
    * ~ 360px
*/
@media screen and (max-width: 360px) {
    footer{
        .foot_menu{
            padding:15px 5px;
            .menu_item{
                padding-left:5px;
            }            
        }
    }
}
/* SUB */

/* Edu Center common */
.edu_wrap{
	.sub_edu{
		margin-top:46px;
		@include tab-style(sub);
	}
	.page_desc{
		margin-top:24px;
	}
	.tab_cont_area {
		margin-top:75px;
		.tab_tit{
			.tit_lst{
				.tit_item{
					width:50%;
					height:60px;
				}
			}
		}
		.tab_cont{
			&:nth-child(2){
				.box{
					padding-top:40px;
				}
			}
		}
	}
	.pagination{
		margin-top:70px;
	}
	.sel_area{
		.sel{
			display:inline-block;
			width:450px;
			&:not(:first-child){
				margin-left:16px;
			}
		}
	}
	
	.example{
		font-size:16px;
		color:#dc0012;
		font-weight:700;
		text-align:center;
		line-height:22px;
		.emphasis{
			display:block;
			font-size:14px;
			font-weight:400;
		}
	}
	
	.search_area{
		margin-top:20px;
		text-align:center;
		font-size:0;
	}
	.btn_area{
		margin-top:42px;
		text-align:center;
	    [class^="btn"]{
	    	&:first-child{
	    		margin-right:10px;
	    	}
	    }
	}
	.btn_more {
	    display: inline-block;
	    width: 160px;
	    height: 50px;
	    padding-top: 15px;
	    border-radius: 25px;
	    @include btn-bg(light_gray);
	    font-size: 16px;
	    font-weight: 700;
	    text-align: center;
	}
}

/* Edu Center */
.edu_center{
	.box{
		width:790px;
		margin:0 auto;
		padding-top:50px;
		&:after{
			display:block;
			clear:both;
			content:'';
		}
		.item{
			float:left;
			width:250px;
			padding:35px 0 28px;
			border-radius:30px;
			border:2px solid #fceaeb;
			box-sizing:border-box;
			text-align: center;
			font-size:16px;
			&:not(:first-child){
				margin-left:20px;
			}
			.box_tit{
				padding:0 25px;
				font-size:22px;
				color:#dc0012;
				font-weight:800;
				white-space:nowrap;
				overflow:hidden;
				text-overflow:ellipsis;
			}
			.tel{
				display:inline-block;
				margin-top:10px;
				padding:6px 24px;
				border-radius:14px;
				background-color:#fceaeb;
				text-align:center;
				font-size:0;
				color:#707070;
				.tit,.txt{
					display:inline-block;
					font-size:14px;
				}
				.tit{
					font-size:12px;
				}
				.txt{
					margin-left:7px;
					font-weight:700;
				}
			}
			.address{
				overflow:hidden;
				height:63px;
				padding:0 22px 0 28px;
				margin-top:20px;
				font-size:14px;
				color:#1a1311;
				line-height:1.57;
			}
			.btn_area{
				margin-top:31px;
				font-size:0;
				.btn_link{
					@include btn-border(red);
					display:inline-block;
					padding:10px 18px;
					border-radius:60px;
					font-size:14px;
					&:before{
						@include aln-m;
					}
					&:last-child{
						margin-left:10px;
					}
				}
			}
		}
	}
}


/* Edu 검색값 page */
.edu_search{
	.name{
		display:table;
		padding:19px 51px;
		margin:44px auto 0;
		border-radius:60px;
		background-color:#fceaeb;
		text-align:center;
		font-size:24px;
		color:#dc0012;
		font-weight:700;
	}

	.address_box{
		overflow:hidden;
		max-width:700px;
		margin:53px auto 0;
		color:#dc0012;
		letter-spacing:-0.3px;
		.tit{
			float:left;
			width:98px;
			padding:11px 0 11px 13.5px;
			box-sizing:border-box;
			font-weight:800;
		}
		.txt{
			width:100%;
			padding:11px 0 11px 98px;
			box-sizing:border-box;
			border-bottom:1px solid #dc0012;
		}
	}

	.map_area{
		overflow:hidden;
		position:relative;
		max-width:900px;
		margin:40px auto 0;
		img{
			width:100%;
			vertical-align:top;
		}
	}
	
	.btn_area{
		margin-top:40px;
		text-align:center;
		font-size:0;
		.btn_link,.btn_back{
			display:inline-block;
			width:210px;
			height:50px;			
			border-radius:60px;
			font-size:16px;
			font-weight:700;
			line-height:20px;
		}
		.btn_link{
			@include btn-border(red);
			padding-top:13px;
		}
		.btn_back{
		    display:inline-block;
		    width:210px;
		    height:50px;
		    padding-top: 13px;
		    @include btn-bg(light_gray);
		    border-radius:25px;
		    font-size:17px;
		    color:#707070;
		    line-height:21px;
		    &:before{
		        display:inline-block;
		        width:20px;
		        height:6px;
		        margin:-3px 9px 0 0;
		        background-image:inline-svg(arr3_left_sm, $red);
		        background-size:100% 100%;
		        color:#dc0012;
		        content:'';
		        vertical-align:middle;
		    }
		}
	}
}

/* Edu 검색값 X page */
.edu_no_search{
	.no_cont{
		position:relative;
		width:348px;
		height:348px;
		margin:60px auto 0;
		border-radius:50%;
		background-color:#f4f4f4;
		line-height:1.5;
		color:#1a1311;
		text-align:center;
		.txt{
			display:inline-block;
			vertical-align:middle;
			.emphasis{
				display:inline-block;
				margin-bottom:9px;
				font-size:40px;
				color:#dc0012;
				font-weight:800;
				line-height:1;
			}
		}
		&:before{
			@include aln_m;
		}
	}
}

@media screen and (max-width:1279px) {
	.edu_wrap{
		.example{
			.emphasis{
				margin-top:8px;
			}
		}
		.tab_area{
			margin-top:40px;
			.tab_cont_area{
				margin-top:41px;
			}
		}
		
		.sel_area{
			.sel{
				width:280px;
				.select{
					color:#dc0012;
					font-weight:700;
					letter-spacing:-0.3px;
					&:after{
						right:30px;
						width:12px;
						height:6px;
					}
				}
				&:not(:first-child){
					margin-left:20px;
				}
			}
		}
	}
	
	.edu_center{
		.box{
			width:530px;
			.item{
				&:nth-child(2n+1){
					clear:both;
					margin-left:0;
				}
				&:nth-child(2n){
					margin-left:30px;
				}
				&:nth-child(n+3){
					margin-top:30px;
				}
			}
		}
		.pagination-container {
			display: none;
		}
	}
	
	.edu_search{
		padding:0 30px;
		.name{
			padding:14px 39px;
			margin-top:20px;
			font-size:20px;
		}
		.address_box{
			max-width:580px;
			margin-top:20px;
			font-size:14px;
			line-height:1.5;
			.tit{
				width:76px;
				padding:6px 0 6px 5.5px;
			}
			.txt{
				padding:6px 0 6px 76px;
			}
		}
		.btn_area{
			margin:30px auto 0;
			.btn_back{
				margin-left:0;
				margin-top:10px;
			}
		}
		.map_area{
			height:300px;
			img{
				position:absolute;
				top:50%;
				left:50%;
				width:auto;
				transform:translate(-50%,-50%);
			}
		}
	}
	.edu_no_search{
		.no_cont{
			width:310px;
			height:310px;
			margin:50px auto 0;
			padding:0 25px;
			box-sizing:border-box;
		}
	} 
}

@media screen and (max-width:639px) {
	.edu_wrap{
		.search_area{
			width:100%;
			padding:0 40px;
			box-sizing:border-box;
		}
		.sel_area{
			.sel{
				display:block;
				margin:0 auto;
				&:not(:first-child){
					margin:20px auto 0;
				}
			}
		}
		
		.tab_area{
			.tab_cont_area{
				margin-top:51px;
			}
		}
	}	

	.edu_center{
		.page_section{
			.page_desc{
				margin-top:24px;
				font-size:14px;
				padding:0 31px;
				br{
					display:none;
				}
			}
		}

		.box{
			width:250px;
			padding-top:74px;
			.item{
				&:nth-child(2n){
					margin-left:0;
				}
				&:nth-child(n+2){
					margin-top:20px;
				}
			}
		}
		.tab_cont{
			.btn_area{
				margin-top:42px;
			}
		}
	}
	.edu_search{
		.btn_area{
			[class^="btn"]{
				display:block;
				margin:0 auto;
				&:first-child{
					margin:0 auto 10px;
				}
			}
		}
	}
}
@media screen and (max-width:359px) {
	.edu_search{
		.map_area{
			position:relative;
			height:0;
			padding-top:100%;
		}
	} 
} 
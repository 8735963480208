/* SVG Icons */

// i {
//     display: inline-block;
// }

$folder: "svg";
@each $icon in svg-list($folder) {
    $url: inline-svg($icon, #fff);
    .#{$icon}:before {
    	content:'';
        background-image: $url;
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}
/* MAIN */
/* Key Visual */
.kv_section {
	position: relative;
	height: 500px;
	.slide_wrap {
		height: 100%;
		.inner {
			@include position(absolute, 172px, 0, null, 0);
			z-index: 1;
			width: 1200px;
			margin: 0 auto;
		}
		.paging {
			bottom: 159px;
			left: 0;
			right: 0;
			width: 1200px;
			margin: 0 auto;
			text-align: left;
		}
	}
}
.kv_slide {
	.slide_item {
		overflow: hidden;
	}
	.h_tit {
		font-family: $pointFontFamily;
		font-weight: bold;
		font-size: 40px;
		letter-spacing: -.08em;
		color: $red;
	}
	.desc {
		margin: 6px 0 0 3px;
		font-family: $pointFontFamily;
		font-size: 20px;
		font-weight: 400;
		color: $red;
	}
	.btn_link {
		display: inline-block;
		width: 159px;
		height: 40px;
		margin-top: 25px;
		padding-top: 10px;
		@include btn-border(red);
		border-radius: 20px;
		font-size: 16px;
		font-weight: 800;
		letter-spacing: -0.03em;
		text-align:center;
	}
	.thumb {
		@include alnX;
		width: auto;
		min-width: 0;
		height: 100%;
		min-height: 0;
		img{
			display:block;
			width:100%;
		}
	}
	.vdo{
		position: relative;
		height:100%;
		video{	
			@include position(absolute,0,null,null,50%);
			@include translateX(-50%);
			min-height:100%;
			object-fit: cover;
		}
		img{
			@include alnX;	
			z-index:-1;
			min-height:100%;
		}
	}
}
.noti_wrap {
	@include position(absolute, null, 0, 0, 0);
	z-index: 1;
	.inner {
		overflow: hidden;
		position: relative;
		max-width: 1200px;
		height: 50px;
		margin: 0 auto;
		padding: 0 76px 0 164px;
		border-radius: 25px 25px 0 0;
		background-color: $red;
		font-size: 0;
		@include box-sizing(border-box);
	}
	.noti_slide {
		height: 100%;
	}
	.h_tit {
		@include alnY;
		left: 44px;
		font-weight: 600;
		font-size: 20px;
		color: $white;
		letter-spacing: -.04em;
	}
	.noti_lst {
		height: 100%;
	}
	.noti_item {
		height: 100%;
		.link {
			display: flex;
			position: relative;
			height: 100%;
			align-items: center;
		}
		.new {
			display: inline-block;
			min-width: 34px;
			height: 15px;
			margin-left: 4px;
			font-size: 10px;
			font-weight: 800;
			border-radius: 7px;
			background: $white;
			color: $red;
			line-height: 17px;
			letter-spacing: -.02em;
			text-align: center;
			vertical-align: middle;
			text-transform: uppercase;
		}
		.tit {
			font-size: 16px;
			font-weight: 400;
			color: $white;
			letter-spacing: -.03em;
			vertical-align: middle;
			@include single-ellipsis;
		}
		.date {
			@include alnY;
			right: 0;
			font-size: 16px;
			color: $white;
			letter-spacing: -.03em;
		}
	}
	.btn_more {
		@include position(absolute, 0, 22px);
		padding: 18px 17px 17px;
		font-size: 0;
		&:after {
			content:'';
			display: block;
			width:14px;
			height:14px;
			background-image:inline-svg(arr_plus_wh);
			background-size: 100% 100%;
		}
	}
}
.event_section {
	position: relative;
	.inner {
		display: flex;
		position: relative;
		width: 100%;
		max-width: 1560px;
		margin-left: auto;
		padding: 73px 0;
		align-items: center;
		justify-content: flex-end;
		@include box-sizing(border-box);
	}
	.tit_box {
		max-width: 330px;
		padding-left: 30px;
		flex: 1;
		box-sizing: border-box;
		.cate {
			display: block;
			margin-bottom: 10px;
			font-family: $pointFontFamily;
			font-size: 20px;
			font-weight:300;
			color: $red;
			letter-spacing: -.04em;
		}
		.h_tit {
			font-family: $pointFontFamily;
			font-size: 34px;
			font-weight: 300;
			color: $red;
			line-height: 1.12;
			letter-spacing: -.068em;
		}
	}
	.btn_link {
		display: block;
		@include btn-size(159px, 40px);
		margin-top: 26px;
		padding-top:10px;
		@include btn-border(red);
		border-width: 2px;
		border-radius: 20px;
		font-size: 16px;
		font-weight: 800;
		letter-spacing: -0.03em;
		text-align:center;
	}
}
.event_slide {
	height: 100%;
	margin: 0;
	flex: 1;
	.event_lst {
		display: flex;
		flex: 1;
	}
	.event_item {
		overflow:hidden;
		width: 350px;
		height: 230px;
		&:first-child {
			margin-left: 0;
		}
		.thumb {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
			&:before {
				content: '';
				@include position(absolute, 0, 0, null, 0);
				z-index: 1;
				width: 100%;
				height: 100%;
				border: 1px solid $gray;
				@include box-sizing(border-box);
			}
			img {
				display: block;
				width: 100%;
				min-height: 100%;
			}
		}
	}
	.paging {
		position: static;
		height: 6px;
		margin-top: 22px;
		text-align: left;
		[class*="bullet"] {
			background-color: $gray;
		}
	}
}
.vdo_section {
	.inner {
		display: flex;
		position: relative;
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 60px;
		@include box-sizing(border-box);
		&:before {
			content: '';
			@include position(absolute, 0, 0, null, 0);
			height: 2px;
			background-color: $red;
		}
	}
	.tit_box {
		flex: 1;
		padding: 70px 0 0 30px;
		&:after {
			content: '';
			display: block;
			margin-top: 21px;
			@include sp-normal('ico_idea');
		}
		.h_tit {
			font-family: $pointFontFamily;
			font-size: 26px;
			font-weight: 300;
			color: $red;
			line-height: 32px;
			letter-spacing: -.052em;
		}
	}
	.vdo_box {
		padding-top: 53px;
		.paging {
			display: none;
		}
	}
	.vdo_lst {
		display: flex;
	}
	.vdo_item {
		position: relative;
		width: 305px;
		margin-left: 20px;
		&:first-child {
			margin-left: 0;
		}
		.thumb {
			display: block;
			position: relative;
			width: 100%;
			height: 229px;
			&:before {
				content: '';
				@include position(absolute, 0, 0, null, 0);
				width: 100%;
				height: 100%;
				z-index: 1;
				border: 1px solid $gray;
				@include box-sizing(border-box);
			}
			img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.name {
			display: block;
			margin-top: 11px;
			font-weight: 400;
			font-size: 16px;
			letter-spacing: -.03em;
			color: $black;
			.point {
				margin-left: 5px;
				font-weight: 800;
			}
		}
		.btn_play {
			@include position(absolute, 0, 0, 0, 0);
			z-index: 1;
			width: 100%;
			height: calc(100% - 29px);
			&:before {
				content: '';
				@include aln;
				@include sp-retina('ico_play');
			}
		}
	}
	.btn_play {
		display: block;
	}
}
.lnk_section {
	overflow:hidden;
	position: relative;
	padding:40px 0 50px;
	&:before{
		@include position(absolute,null,0,0,0);
		height:293px;
		background:url(../img/bg_lnk_section.png) no-repeat;
		background-position:50% 100%;
		background-size: cover;
		content:'';
	}
	.tit_box{
		font-size:0;
		text-align:center;
		.btn_link{
			display:inline-block;
			position: relative;
			height:30px;
			margin-left:9px;
			padding:5px 20px 0;
			@include btn-border(red);
			@include border-radius(15px);
			font-size: 16px;
			font-weight: 800;
			letter-spacing: -.03em;
			text-align:left;
			vertical-align: middle;
			&:after{
		        display:inline-block;
		        width:20px;
		        height:6px;
		        margin:-3px 0 0 5px;
		        background-image:inline-svg(arr3_right_sm, $red);
		        background-size:100% 100%;
		        color:#dc0012;
		        content:'';
		        vertical-align:middle;
		    }
		    &:hover{
		    	&:after{
		    		background-image:inline-svg(arr3_right_sm, $white);
		    	}
		    }
		}
	}
	.h_tit{
		display:inline-block;
		font-size:25px;
		font-weight:300;
		font-family: $pointFontFamily;
		color:$red;
		letter-spacing: -0.05em;
		vertical-align: middle;
		.point{			
			font-size:26px;
			font-weight:bold;
		}
	}
	.lnk_lst {
		margin-top:24px;
		font-size:0;
		text-align:center;
	}
	.lnk_item {
		display:inline-block;
		position: relative;
		width:300px;
		height:300px;
		margin-left:20px;
		&:before{
			content:'';
			@include position(absolute,0,0,null,0);
			width:300px;
			height:300px;
			margin:0 auto;
			border:2px solid transparent;
			border-radius:50%;
			box-sizing:border-box;
			transition: border .2s linear;	
		}
		&:after{	
			@include position(absolute,51px,0,null,0);			
			width:227px;
			height:163px;
			margin:0 auto;
			background-size:100% 100%;
			opacity:0;
			transition: opacity .2s linear;
			content:'';
		}
		&:hover,
		&:focus{
			&:before{
			border-color:$red;
			}
			&:after{
				opacity:1;
			}
		}
		&:first-child{		
			margin-left:0;		
			&:before{					
				background-color:$white;		
				box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);		
			}
			
			&:after{
				background-image:url(../img/bg_pattern.png);
			}		
		} 
		&:nth-child(2){
			&:before{	
				background-color:$pink;
			}
			&:after{
				background-image:url(../img/bg_pattern2.png);
			}
		} 
		&:last-child{			
			&:before{	
				background-color:$light_pink;
			}
			&:after{
				background-image:url(../img/bg_pattern3.png);
			}
		} 
		.inner{
			@include aln;
			z-index:1;
			vertical-align: middle;
			&:before{
				@include aln_m;
			}
		}
		.num{
			display:block;			
			font-size:30px;
			color:$red;
			letter-spacing: -0.06em;
		}
		.tit{
			display:block;
			margin-top:9px;
			font-family: $pointFontFamily;
			font-size:30px;
			font-weight:bold;
			color:$red;
			letter-spacing: -0.06em;
		}
		.desc{
			margin-top:19px;
			font-size:16px;
			font-weight:400;
			color:$red;
			line-height:22px;
			letter-spacing: -0.063em;
		}
		.btn_link{
			display:inline-block;
			position: relative;
			height:30px;
			margin-top:32px;
			padding:4px 37px 0 36px;
			@include btn-border(red);
			@include border-radius(15px);
			font-size: 16px;
			font-weight: 800;
			letter-spacing: -.03em;
		}
	}
	.paging{
		display:none;
	}
}
.sch_section {
	position: relative;
	&:before {
		content: '';
		@include position(absolute, 0, 0, null, 0);
		width: 100%;
		height: 1px;
		background-color: $gray;
	}
	.inner {
		position: relative;
		max-width: 1200px;
		margin: 0 auto;
		padding: 57px 0 61px 31px;
		@include box-sizing(border-box);
		&:before {
			content: '';
			@include position(absolute, 0, null, null, 0);
			z-index: -1;
			width: 100%;
			height: 100%;
			background: url(../img/bg_search.png) no-repeat;
			background-size: 100% auto;
			background-position: center;
		}
	}
	.h_tit {
		font-family: $pointFontFamily;
		font-size: 24px;
		font-weight: 600;
		color: $red;
		letter-spacing: -.05em;
	}
	.desc {
		margin-top: 18px;
		font-size: 16px;
		font-weight: 400;
		color: $red;
		line-height: 26px;
		letter-spacing: -.03em;
	}
	.btn_link {
		@include position(absolute, null, 110px, 57px, null);
		@include btn-size(245px, 50px);
		padding-top:14px;
		@include btn-bg(red);
		@include border-radius(25px);
		font-weight: 800;
		text-align:center;
		&:before {
			content: '';
			@include position(absolute, -74px, 0, null, 0);
			margin: 0 auto;
			@include sp-retina('img_girl');
		}
	}
}
.introduce_section {
	position: relative;	
	padding-top: 253px;
	&:before {
		content: '';
		@include alnX;
		top: 8px;
		@include sp-retina('icon_crown');
	}
	&:after{
		content:'';
		@include position(absolute, 0, 0, null,0);
		z-index:-1;
		width:100%;
		height:316px;
		background: url(../img/bg_introduce.png) no-repeat 50% 50%;
		background-size: cover;
	}
	.h_tit {
		@include position(absolute,122px, 0, null,0);
		font-size: 30px;
		line-height: 34px;
		letter-spacing: -0.6px;
		font-weight:300;
		text-align: center;
		color: $red;
		font-family: $pointFontFamily;
		.point {
			display: block;
			font-size: 32px;
			font-weight:700;
			letter-spacing: -0.64px;
			margin-bottom: 5px;
		}
	}
	.cont_item{		
		padding:96px 0 80px;
		&:nth-child(1) {			
			.thumb{
				width:381px;
				height:141px;
			}
			.intro_tit{
				margin-top:63px;
			}
			.btn_info{
				margin-top:86px;
			}
		}
		&:nth-child(2) {
			.thumb{
				width:555px;
				height:348px;
			}
			.intro_tit{
				margin-top:31px;
			}
			.btn_info{
				margin-top:60px;
			}
		}
		&:nth-child(3) {			
			.intro_tit{
				margin-top:82px;
			}
			.btn_info{
				margin-top:44px;
			}
		}
		&:nth-child(4) {
			.thumb{
				width:434px;
				height:239px;
			}
			.intro_tit{
				margin-top:23px;
			}
			.btn_info{
				margin-top:31px;
			}
		}
		.intro_tit {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: -0.4px;
			color: $red;
			display: block;
			font-family: $pointFontFamily;
			font-weight:800;
		}
		.intro_des {
			display: inline-block;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.32px;
			color: $black;
			font-weight:400;			
			margin-top: 12px;
			word-break: keep-all;
			span {
				display: block;
				em {
					font-weight:800;
				}
			}
		}
		.info_lst {
			margin-top: 12px;
		}
		.thumb{
			display:block;
			margin:0 auto;
			img{
				display:block;
				width:100%;
				height:100%;
			}
		}
	}
	.inner {
		text-align: center;
	}
	.tab{
		@include tab-style(main);
		.tab_cont{
			max-width:1200px;
			margin: 0 auto;
			text-align:center;		
		}
	}
	.navi_box{
		@include position(absolute,null,0,70px,0);
		
		// K. 2019.06.17 (common으로 분리)
		// .loading{
		// 	display:none;
		// 	@include position(absolute,8px,null,null,0);
		// 	z-index:-1;
		// }
		// a{
		// 	margin-top:-8px;
		// 	&:hover{
		// 		~ .loading{
		// 			display:block;
		// 		}
		// 	}
		// 	&[class*="disabled"]{
		// 		opacity:.2;
		// 	}
		// }
	}
	.btn_prev {
		// position:absolute;
		left:25px;
		width: 131px;
		height: 17px;
		// background-image:inline-svg(arr3_left, $red);
		// background-size: 100% 100%;
		// &:hover{
		// 	~ .loading{
		// 		left:0;
		// 		right:auto;
		// 	}
		// }
	}
	.btn_next {
		// position:absolute;
		right:25px;
		width: 131px;
		height: 17px;
		// background-image:inline-svg(arr3_right, $red);
		// background-size: 100% 100%;
		// &:hover{
		// 	+ .loading{
		// 		left:auto;
		// 		right:0;
		// 	}
		// }
	}
	.circle_lst{
		font-size:0;		
		.circle_item{
			display:inline-block;
			width:210px;
			height:100px;
			margin-left:30px;
			border:2px dashed $red;
			border-radius:50px;
			background-color:$white;
			box-sizing:border-box;
			cursor:pointer;
			transform:translateZ(0);
			transition:all .3s ease;
			&:before{
				@include aln_m;
			}
			&:first-child{
				margin-left:0;
			}
			&:hover,
			&:focus{
				background-color:$red;
				box-shadow: 0 3px 18px 0 rgba(220, 0, 18, 0.12);
				transform:translateY(-20px) translateZ(0);
				.txt_box{
					color:$white;
				}
			}
		}
		.txt_box{
			display:inline-block;	
			width:100%;	
			font-family: $pointFontFamily;
			font-size:28px;
			font-weight:600;
			color:$red;
			letter-spacing:-.06em;
			vertical-align:middle;
		}
		.tit{
			display:block;
			position: relative;
			margin-bottom:7px;
			padding-bottom:3px;
			font-size:20px;
			font-weight:600;
			border-bottom:1px solid #e5404e;
		}
	}
	.paging{
		opacity:0;
	}
	.pagination_area {
		text-align: center;
	}
	.btn_info {
		display: inline-block;
		min-width: 290px;
		padding: 15px 20px;
		@include btn-border(red);
		border-radius: 25px;
		font-weight:800;
		font-size: 16px;
		letter-spacing: -0.32px;
	}
}
// 1920 ~
@media (min-width:1919px) {
	.kv_section{
		max-width:$screen-xlg;
		margin:0 auto;
	}
	.kv_slide {
		.thumb {
			width: 100%;
			height: 100%;
		}
	}
	.event_section {
		max-width:$screen-xlg;
		margin:0 auto;
		.inner {
			max-width: 1560px;
		}
	}
}
// ~ 1280px (태블릿/모바일)
@media (max-width:1279px) {
	.kv_section {
		height:540px;
		.slide_wrap {
			.inner {
				top: 0;
				bottom:0;
				width: 100%;
				margin:42px 0 50px;
				text-align: center;
			}
			.paging {
				display: none;
			}
		}
	}
	.kv_slide {
		.h_tit {
			font-size: 25px;
		}
		.desc {
			font-size: 16px;
			line-height: 1.13;
		}
		.btn_link {
			@include position(absolute,null,0,0,0);
			bottom:38px;
			margin: 0 auto;
		}
		.thumb {
			margin-left: -160px;
		}
		.vdo{
			video{
				@include translateX(-61%);
				object-position:61% 100%;
			}
			img{
				@include translateX(-59%);
			}
		}
	}
	.noti_wrap {
		.h_tit {
			left: 30px;
			font-size: 16px;
		}
		.inner {
			max-width: none;
			margin: 0;
			padding: 0 57px 0 104px;
		}
		.noti_item {
			.date {
				display: none;
			}
		}
		.btn_more {
			right: 13px;
		}
	}
	.event_section {
		.inner {
			display: block;
			max-width: none;
			padding: 51px 0 0 30px;
		}
		.tit_box {
			position: static;
			max-width: none;
			padding-left: 0;
			text-align: center;
			.cate {
				margin-bottom: 6px;
				font-size: 16px;
			}
			.h_tit {
				font-size: 24px;
				font-weight: 600;
			}
		}
		.btn_link {
			@include position(absolute, null, 0, 0, 0);
			z-index: 10;
			margin: 0 auto;
		}
	}
	.event_slide {
		position: relative;
		width: 100%;
		height: 100%;
		margin-top: 17px;
		padding-bottom: 74px;
		.event_item {
			width: 49.182%;
			height: 100%;
			.thumb {
				display: block;
				overflow: hidden;
				position: relative;
				width: 100%;
				height: 0;
				padding-top: 66%;
				img {
					@include aln;
				}
			}
		}
		.paging {
			margin-top: 17px;
			text-align: center;
		}
	}
	.vdo_section {
		.inner {
			display: block;
			width: 100%;
			padding: 41px 30px 54px;
			&:before {
				background-color:$light_gray;
			}
		}
		.tit_box {
			padding: 0;
			text-align: center;
			&:after {
				display: none;
			}
		}
		.vdo_box {
			padding-top: 44px;
			position: relative;
			width: 100%;
			height: 100%;
			padding-bottom: 35px;
			.paging {
				display: block;
				bottom: 0;
			}
		}
		.vdo_item {
			width: 50%;
			height: 100%;
			margin: 0;
			.thumb {
				display: block;
				overflow: hidden;
				position: relative;
				width: 100%;
				height: 0;
				padding-top: 66%;
				img {
					@include aln;
				}
			}
			.name {
				text-align: center;
			}
		}
	}
	.lnk_section{
		padding:33px 0 20px;
		&:before{
			bottom:49px;
			height: 215px;
		}
		&:after{
			content:'';
			@include position(absolute,0,0,null,0);
			width:100%;
			height:1px;
			background-color:$light_gray;
		}		
		.h_tit{
			font-size:22px;
			line-height:26px;
			.point{
				display:block;
				margin-bottom:11px;
			}
		}
		.lnk_box{
			padding-bottom: 49px;
		}
		.lnk_lst{
			margin-top:20px;
		}
		.lnk_item{
			margin-left:0;
		}
		.tit_box{
			.btn_link{
				display:none;
			}
		}
		.paging{
			display:block;
			bottom:0;
		}
	}
	.sch_section {
		&:before{
			display:none;
		}
		.inner {
			max-width: none;
			padding: 38px 0 44px;
			text-align: center;
			&:before {
				background-image: url(../img/bg_search2.png);
				background-size: auto 100%;
			}
		}
		.btn_link {
			display: block;
			position: relative;
			right:auto;
			bottom: auto;
			margin: 95px auto 0;
		}
		.h_tit {
			font-size: 20px;
			line-height: 29px;
			letter-spacing: -.04em;
		}
		.desc {
			display: none;
		}
	}
	.pagination_area {
		.btn_area {
			display: none;
		}
	}	
	.introduce_section {
		margin-top:27px;
		padding-bottom:51px;
		background-position:50% 5px;
		.inner{
			padding:0 15px;
		}
		.h_tit {
			font-size: 21px;
			letter-spacing: -0.42px;
			margin-bottom: 69px;
		}
		.cont_item{
			padding-top:43px;
			padding-bottom:0;
			.intro_tit {
				font-size: 18px;
			}
			&:nth-child(1) {
				.thumb{
					width:calc(381px * 0.58);
					height:calc(141px * 0.58);
				}
				.intro_tit{
					margin-top:34px;
				}
			}
			&:nth-child(2) {
				.thumb{
					width:calc(555px * 0.50);
					height:calc(348px * 0.50);
				}
				.intro_tit{
					margin-top:34px;
				}
			}
			&:nth-child(3) {
				.intro_tit{
					margin-top:34px;
				}
			}
			&:nth-child(4) {
				.thumb{
					width:calc(434px * 0.58);
					height:calc(239px * 0.58);
				}
				.intro_tit{
					margin-top:34px;
				}
				.btn_info {
					display:inline-block;
					min-width:auto;
				}
			}
		}
		.btn_info {
			display: none;
		}
		.navi_box{
			display: none;
		}
		.paging{			
			position:static;
			padding-top:55px;
			opacity:1;
		}
		.cont_item{
			min-height:auto;
		}
	}
}
// 640
@media (max-width:639px) {
	.kv_slide .vdo video{object-position: 65% 100%;}
	.event_section {
		.inner {
			padding-right: 30px;
		}
	}
	.event_slide {
		.event_item {
			width: 100%;
		}
	}
	.noti_wrap {
		.inner {
			margin: 0;
			border-top-right-radius: 0;
		}
	}
	.vdo_section {
		.inner {
			padding:33px 30px 44px;
		}
		.vdo_item {
			width: 100%;
		}
	}
	.sch_section {
		&:before {
			display: none;
		}
		.inner {
			padding: 46px 0 50px;
		}
		.h_tit {
			font-size: 16px;
			line-height: 26px;
			letter-spacing: -.03em;
		}
		.btn_link {
			display: block;
			position: relative;
			bottom: 0;
			margin-top: 92px;
		}
	}
	.introduce_section{
		.circle_lst{	
			.circle_item{
				display:block;
				margin:20px auto 0 !important;
			}
		}
	}
}

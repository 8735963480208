/* GUIDE */
.test_wrap{
	/* BUTTON */
	.btn{
		&.btn_guide{
			@include btn-size(200px,40px); // button의 width,height
			@include btn-bg(red); // background-color:red일 때;
			@include border-radius(20px); // border-radius값
		}
		&.btn_guide2{
			@include btn-size(200px,40px);
			@include btn-border(red); // border-color:red일 때;
			@include border-radius(20px);
		}
		&.btn_guide3{
			@include btn-size(200px,40px);
			@include btn-border(white); // border-color:white일 때;
			@include border-radius(20px);
		}
	}
	/* Icon */
	.ico_guide{
		@include sp-normal('logo_junior'); // nomal
	}
	.ico_logo{
		@include sp-retina('logo_junior'); //retina
	}
	.ico_logo_tuntun_guide{
		@include sp-normal('logo_tuntun'); // nomal
	}
	.ico_logo_tuntun{
		@include sp-retina('logo_tuntun'); //retina
	}
	.tab{
		&.main{
			@include tab-style(main);
		}
		&.sub{
			@include tab-style(sub);
			.tit_link {
			    padding-top: 21px;
			    font-size: 18px;
			    letter-spacing: -0.04em;
			}
		}
	}
	.cate_item{
		padding:40px 24px 0;
		.thumb{
			height:280px;
			background-color: #f4f4f4;
		}
		&.full{
			.thumb{
				height:442px;
			}
		}
	}
}

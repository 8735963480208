/* Layer */
$ly: ly;

.ly_wrap{
	display:none;
	@include position(fixed,0,0,0,0);
	width:100%;
	padding:0 25px;
	@include background-opacity(#000,.6);
	@include box-sizing(border-box);
    // &:before{
    // 	content:'';
    // 	@include position(absolute,0,0,0,0);
    // 	width:100%;
    // 	height:100%;
    // 	@include background-opacity(#000,.6);
    // }   
    &.is_active{
    	display:flex;
    	z-index:z(layer);
		align-items: center;
		justify-content: center;
    }
    .ly_header{
    	display:inline-block;
    	&:before{
        	@include aln_m;
        }
        *{
        	display:inline-block;
        	vertical-align:middle;
        }
    }   
    .ly_inner{
    	position: relative;
    	width:100%;
    	min-width:310px;
        height:100%;
        background-color:$white;
    }
    .btn_close{
		position:absolute;
		width:60px;
		height:60px;
		&:before{
			content:'';
			@include aln;
		}
    }
}
.ly_privacy{	
	.ly_inner{
		max-width:1100px;
		height:700px;
		padding:90px 60px 63px;
		@include box-sizing(border-box);
	}
	.ly_tit{
		@include position(absolute,45px);
        font-size:22px;
        font-weight:800;
        color:$red;
        letter-spacing:-.04em;
    }
	.ly_cont{
		overflow:hidden;
		height:100%;
		border:1px solid #fcd8da;
		background-color:#fceaeb;
    }
    .cont{
		margin-bottom:34px;
		padding:33px 0 0 27px;
		*{
			color:$black;
			font-size:14px;
			line-height:22px;
		}
		.tit{
			margin-top:22px;
		}
		.sub_tit{
			margin-top:22px;
		}
		.desc{
			margin-top:22px;
			&:first-child{
				margin-top:0;
			}
		}
		.info_lst{
			margin-top:22px;
			li{
				margin-top:10px;
				&:first-child{
					margin-top:0;
				}
			}
		}
		.num_lst{
			margin-top:12px;
			li{
				margin-top:3px;
				&:first-child{
					margin-top:0;
				}
			}
		}
		.txt_lst{
			margin-top:5px;
			li{
				position:relative;
				margin-top:4px;
				padding-left:12px;
				&:before{
					content:'';
					position:absolute;
					top:8px;
					left:5px;
					width:2px;
					height:2px;
					border-radius:50%;
					background:$black;
				}
			}
		}
		.tb_type{
			width:100%;
			margin-top:6px;
			table-layout: fixed;
			border:1px solid $black;
			border-width:0 1px 1px 0;
			th{
				padding:5px 3px;
				border:1px solid $black;
				border-width:1px 0 0 1px;
			}
			td{
				padding:5px 3px;
				border:1px solid $black;
				border-width:1px 0 0 1px;
				text-align:center;
				word-break: break-word;
			}
		}
	}
    .scroll_wrap{
    	overflow-x:hidden;
    	overflow-y:auto;
    	height:100%;
    	-webkit-overflow-scrolling: touch;
    }
    .btn_close{
    	top:28px;
    	right:45px;
		&:before{
			width:32px;
			height:32px;
			background-image:inline-svg(ico_close_rd, $red);
			background-size: 100% 100%;
		}
    }
}
.ly_vdo, .ly_img{
	.ly_inner{
		max-width:643px;
		height:auto;
		padding-top:60px;
		@include box-sizing(border-box);
	}
	.ly_header{
		@include position(absolute,0,0,null,0);
		height:60px;
		padding-left:20px;
		.ly_tit{
			font-size:20px;
			letter-spacing:-.04em;
		}
		.name{
			margin-left:10px;
			font-size:16px;
			color:$white;
			letter-spacing:-.03em;
		}
	}	
	.vdo_wrap, .img_wrap{
		position:relative;
		z-index:2;
		width:100%;
		height:auto;
		padding-top:75%;
		iframe{
			@include position(absolute,0,null,null,0);
			width: 100%;
			height: 100%;
			border:0;
		}
	}
	.loading{
		@include aln;
		z-index:1;
	}
	.btn_close{
		top:0;
		right:0;
		&:before{
			width: 32px;
    		height: 32px;
			background-size:100% 100%;
		}
    }
}
.ly_vdo{
	.ly_inner{
		background-color:#000;
	}
	.ly_header{
		background-color: $red;
		.ly_tit{
			color:$white;
		}
	}
	.btn_close{
		&:before{
			background-image:inline-svg(ico_close_wh, $white);
		}
	}
}
.ly_img{
	.ly_inner{
		background-color:#fff;
	}
	.ly_header{
		background-color: $light_pink;
		.ly_tit{
			color:$red;
		}
	}
	.img_wrap {
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
		}
	}
	.btn_close{
		&:before{
			background-image:inline-svg(ico_close_rd, $red);
		}
	}
}
// 1280
@media (max-width:1279px){
	.ly_privacy{
		.ly_inner{
			max-width:none;
			height:480px;
			padding:68px 20px 32px;
		}
		.ly_tit{
			top:28px;
		}
		.cont{
			margin-bottom:16px;
			padding:16px 15px 0;
		}
		.btn_close{
			top:9px;
			right:5px;
		}
	}
}
// 640
@media (max-width:639px){
	.ly_vdo{
		.ly_header{
			.name{
				display:none;
			}
		}
	}
}
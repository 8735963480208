/* List */
//텍스트 리스트(블릿이 있는 리스트)
.txt_lst{
    .txt_item{
        position: relative;
        margin-top:20px;
        padding-left:19px;
        font-size:16px;
        font-weight:400;
        color:$black;
        letter-spacing:-.03em;
        &:before{
            content:'';
            @include position(absolute,50%,null,null,0);
            width:6px;
            height:6px;
            margin-top:-3px;
            border-radius:50%;
            background-color:$red;
        }
        &:first-child{
            margin-top:0;
        }
    }
}
//텍스트 리스트(세부 타이틀(bold)이 있는 리스트)
.info_lst{
    .info_item{
        font-size: 16px;
        font-weight:400;
        color:$black;
        line-height: 1.63;
        letter-spacing:-.03em;
    }
    .tit{
        display:inline-block;
        margin-right:11px;
        font-weight:800;
    }
}
//이미지 리스트
.img_lst{
    display:block;
    font-size:0;
    text-align:center;
    .img_item{
        overflow:hidden;
        display:inline-block;
        img{
            display:block;
            width:100%;
            min-height:100%;
        }
    }
}
//서브 타이틀이 있는 리스트
.cate_lst{
    display:block;
    font-size:0;    
    .cate{
        display:inline-block;
        margin-bottom:10px;
        font-size:16px;
        color:$black;
        letter-spacing:-.03em;
    }
    .cate_item{
        display:inline-block;
        width:50%;
        font-size: 16px;
        color:$black;
        letter-spacing:-.03em;
        box-sizing: border-box;
        &.full{
            width:100%;
        }       
        .thumb{
            display:block;
            position:relative;
            max-width:100%;
        }
        img{
            @include aln;
            max-width: 100%;
            max-height: 100%;
            margin:0 auto;
        }
    }
}

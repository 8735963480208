/* button */
@mixin btn-size($width:100px, $height:50px) {
    width:$width;
    height:$height;
}
@mixin btn-bg($bg:red) {
    @if $bg == red {   
        border:2px solid $red;     
        background-color:$red;
        color:$white;
        box-sizing:border-box;
        &:hover,
        &:focus{
            background-color:$white;
            color:$red;
            transition:all 0.4s ease;
        }
    }
	@if $bg == light_gray {
        border:2px solid $light_gray;     
		background-color:$light_gray;
        color:#707070;
        box-sizing:border-box;
        &:hover,
        &:focus{
            border-color:$light_gray;
            background-color:$white;
            transition:all 0.4s ease;
        }
	}
}
@mixin btn-border($border:red) {
    @if $border == red {       
        border:2px solid $red; 
        background-color:$white;
        color:$red;
        box-sizing:border-box;
        &:hover,
        &:focus{
            border-color:$red;
            background-color:$red;
            color:$white;
            transition:all 0.4s ease;
        }
    }
    @if $border == white {       
        border:2px solid $white; 
        background-color:$red;
        color:$white;
        box-sizing:border-box;
        &:hover,
        &:focus{
            border-color:$red;
            background-color:$white;
            color:$red;
            transition:all 0.4s ease;
        }
    }
	@if $border == gray2 {       
        border:2px solid $gray2; 
        background-color:$white;
        color:$gray2;
        box-sizing:border-box;
        &:hover,
        &:focus{
            border-color:$gray2;
            background-color:$gray2;
            color:$white;
            transition:all 0.4s ease;
        }
    }
}
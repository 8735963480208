/* SUB */

/* tuntun info */

.info_wrap {
	.btn_area{
	    margin-top:30px;
	    text-align:center;
	}
	.btn_more {
	    display: inline-block;
	    width: 160px;
	    height: 50px;
	    padding-top: 15px;
	    border-radius: 25px;
	    @include btn-bg(light_gray);
	    font-size: 16px;
	    font-weight: 700;
	    text-align: center;
	}
	.btn_back{
	    display:inline-block;
	    width:210px;
	    height:50px;
	    padding-top: 13px;
	    @include btn-bg(light_gray);
	    border-radius:25px;
	    font-size:17px;
	    color:#707070;
	    line-height:21px;
	    &:before{
	        display:inline-block;
	        width:20px;
	        height:6px;
	        margin:-3px 9px 0 0;
	        background-image:inline-svg(arr3_left_sm, $red);
	        background-size:100% 100%;
	        color:#dc0012;
	        vertical-align:middle;
	        content:'';	        
	    }
	}
}
.sub_faq {
	@include tab-style(sub);
	margin-top:38px;
}
.noti {
	.pagination{
		margin-top:54px;
	}
	.lst_tb {
		margin-top: 34px;
		.tit_item {
			&.tit_num {
				width: 7.5%;
			}
			&.tit_subject {
				flex: 1;
				text-align: left;
			}
			&.tit_date {
				width: 11%;
				padding-left: 10px;
			}
		}
		.tb_cont {
			.num {
				width: 7.5%;
				.tit_box {
					width: 81.5%;
				}
				.date {
					width: 11%;
				}
			}
		}
		.cont_box {
			padding: 40px 70px;
			font-size: 16px;
			font-weight: 400;
			color: $black;
			line-height: 20px;
			letter-spacing: -.03em;
		}

	}
}
.event {
	.pagination {
		margin-top: 40px;
	}
	.lst_tb {
		margin-top: 34px;
		.tb_tit {
			position: relative;
			padding: 0 30px 0 64px;
		}
		.tit_item {
			&.tit_txt {
				max-width: 65%;
				font-weight: 800;
				font-size: 16px;
				color: $red;
				letter-spacing: -.03em;
				@include single-ellipsis;
			}
			&.tit_cate {
				@include alnY;
				right: 30px;
				font-size: 0;
			}
			&.tit_date {
				padding-left: 10px;
				font-weight: 400;
				font-size: 14px;
				color: $black;
				letter-spacing: -.03em;
			}
			.cate {
				display: inline-block;
				margin-left: 10px;
				height: 24px;
				padding: 3px 16px;
				border-radius: 12px;
				font-size: 14px;
				color: $white;
				background-color: $red;
				line-height: 18px;
				box-sizing: border-box;
				&.is_end {
					color: #707070;
					background-color: #ddd;
				}
			}
		}
		.cont_box {
			padding: 40px 0;
			img {
				display: block;
				max-width: 100%;
				height: auto;
				margin: 0 auto;
			}
			iframe {
				width: 100%;
			}
		}
	}
	.filter_box {
		margin-top: 37px;
		padding: 0 30px;
	}
	.btn_area {
		margin-top: 40px;
		text-align: center;
	}
}
.faq {
	.pagination {
		margin-top: 34px;
	}
}
.filter_box {
	display: flex;
	position: relative;
	height: 67px;
	background-color: $light_gray;
	font-size: 0;
	align-items: center;
	justify-content: center;
	.tit {
		font-size: 16px;
		font-weight: 400;
		color: $black;
		letter-spacing: -.03em;
		white-space: nowrap;
		&:before {
			content: '';
			display: inline-block;
			width: 15px;
			height: 16px;
			margin-right: 6px;
			background-image: inline-svg(ico_filter, #000);
			background-size: 100% 100%;
			vertical-align: middle;
		}
	}
}
.filter_lst {
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	margin-left: 28px;
	-webkit-overflow-scrolling: touch;
	.filter_item {
		position: relative;
		margin-left: 30px;
		&:first-child {
			margin-left: 0;
		}
	}
	.filter_chk {
		@include position(absolute, 0, 0, 0, 0);
		z-index: -1;
		width: 100%;
		height: 100%;
		border: 0;
		background: none;
		&:checked+.filter_lbl {
			background-color: $red;
			color: $white;
		}
	}
	.filter_lbl {
		display: block;
		min-width: 80px;
		height: 40px;
		padding: 0 18px;
		font-size: 16px;
		font-weight: 400;
		border-radius: 20px;
		background-color: $white;
		color: #707070;
		line-height: 40px;
		letter-spacing: -.03em;
		white-space: nowrap;
		text-align: center;
		cursor: pointer;
		box-sizing: border-box;
	}
}
.evt_lst {
	margin: 5px -7.5px 0;
	font-size: 0;
}
.evt_item {
	display: inline-block;
	width: 50%;
	padding: 20px 10px 0;
	@include box-sizing(border-box);
	.evt_link {
		display: block;
		padding: 16px 15px 0;
		border: 1px solid $gray;
		@include box-sizing(border-box);
	}
	.thumb {
		overflow: hidden;
		display: block;
		width: 100%;
		height: 230px; // border:1px solid $gray;
		// @include box-sizing(border-box);
		img {
			display: block;
			width: 100%;
			min-height: 100%;
		}
	}
	.txt_box {
		position: relative;
		padding: 15px 85px 29px 15px;
	}
	.cate {
		@include position(absolute, 15px, 0);
		min-width: 70px;
		height: 24px;
		border-radius: 12px;
		background-color: $red;
		color: $white;
		font-size: 14px;
		font-weight: 900;
		letter-spacing: -.03em;
		line-height: 24px;
		text-align: center;
		&.is_end {
			background-color: #ddd;
			color: #707070;
		}
	}
	.tit {
		display: block;
		font-size: 16px;
		font-weight: 700;
		color: $black;
		letter-spacing: -.03em;
		@include single-ellipsis;
	}
	.date {
		display: block;
		margin-top: 6px;
		font-size: 14px;
		font-weight: 400;
		color: $black;
		letter-spacing: -.03em;
	}
}
.lst_tb {
	border: 1px solid #ffc4c7;
	box-sizing: border-box;
	.tb_tit {
		display: flex;
		width: 100%;
		height: 60px;
		border-bottom: 1px solid #ffc4c7;
		background-color: $light_pink;
		align-items: center;
		box-sizing: border-box;
	}
	.tit_item {
		font-size: 16px;
		font-weight: 700;
		color: $red;
		letter-spacing: -0.3px;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;
	}
	.tb_cont {
		.num {
			width: 7.5%;
			font-size: 15px;
			font-weight: 400;
			color: $black;
			letter-spacing: -.03em;
			text-align: center;
			vertical-align: middle;
		}
		.tit_box {
			display: flex;
			position: relative;
			width: 81.5%;
			height: 100%;
			align-items: center;
		}
		.tit {
			display: inline-block;
			font-size: 15px;
			font-weight: 400;
			color: $black;
			letter-spacing: -.03em;
			vertical-align: middle;
			@include single-ellipsis;
		}
		.new {
			display: inline-block;
			height: 15px;
			margin-left: 12px;
			padding: 2px 6px 0;
			font-size: 10px;
			font-weight: 800;
			color: $white;
			border-radius: 7.5px;
			background-color: $red;
			letter-spacing: -.02em;
			vertical-align: middle;
			text-transform: uppercase;
			box-sizing: border-box;
		}
		.date {
			width: 11%;
			padding-left: 10px;
			font-size: 15px;
			font-weight: 400;
			color: $black;
			letter-spacing: -.03em;
			text-align: center;
		}
	}
	.cont_item {
		display: flex;
		position: relative;
		height: 60px;
		border-bottom: 1px solid #ffc4c7;
		align-items: center;
		&:last-child {
			border-bottom: none;
		}
		&.top_fixed {
			background-color: $light_gray;
		}
	}
}
.lst_faq {
	margin-top: 33px;
	.cont_item {
		margin-top: 20px;
		&:first-child {
			margin-top: 0;
		}
		&.is_active {
			.acc_header {
				background-color: $light_pink;
				box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
				.tit {
					color: $red;
				}
				.btn_link {
					&:before {
						background-image: inline-svg(arr2_up, $white);
					}
					&:after {
						border-color: $red;
						background-color: $red;
					}
				}
			}
			.acc_body {
				display: block;
				background-color: #fff5f6;
			}
		}
	}
}
.acc_header {
	display: flex;
	position: relative;
	height: 60px;
	padding: 0 98px 0 40px;
	background-color: #fff;
	align-items: center;
	box-sizing: border-box;
	transition: background .1s ease;
	&:before {
		content: '';
		@include position(absolute, 0, null, null, 0);
		z-index: 1;
		width: 100%;
		height: 100%;
		border: 1px solid #ffc4c7;
		box-sizing: border-box;
	}
	.cate {
		font-size: 16px;
		font-weight: 900;
		color: $red;
		letter-spacing: -.03em;
	}
	.tit {
		flex: 1;
		margin-left: 30px;
		font-size: 16px;
		font-weight: 700;
		color: $black;
		letter-spacing: -.03em;
		@include single-ellipsis;
		transition: color .1s ease;
	}
	.btn_link {
		@include position(absolute, 0, null, null, 0);
		z-index: 1;
		width: 100%;
		height: 100%;
		outline: none;
		&:before {
			content: '';
			@include alnY;
			right: 22px;
			z-index: 2;
			width: 14px;
			height: 8px;
			background-image: inline-svg(arr2_down, $red);
			background-size: 100% 100%;
		}
		&:after {
			content: '';
			@include alnY;
			right: 0;
			width: 58px;
			height: 100%;
			border: 1px solid #ffc4c7;
			background-color: #fceaeb;
			box-sizing: border-box;
			transition: background .1s ease;
		}
	}
}
.acc_body {
	display: none;
	padding: 24px 40px;
	background-color: #fff5f6;
	font-size: 14px;
	color: $black;
	line-height: 22px;
	letter-spacing: -.03em;
} // 1280
@media screen and (max-width: 1279px) {
	.info_wrap {
		.page_desc {
			margin-top: 6px;
		}
	}
	.filter_lst{
		.filter_item{
			margin-left:20px;
		}
	}
	.noti {
		padding-left:30px;
		padding-right:30px;
		.lst_tb {
			margin-top:22px;
			.cont_box{
				padding:20px;
			}
		}		
	}
	.event {
		.filter_box{
			margin-top: 22px;
			padding-right: 0;
		}
		.lst_tb {
			margin-top:22px;
			.tb_tit {
				padding: 0 15px;
			}
			.tit_item{
				&.tit_cate{
					right:15px;
				}
				.cate{
					margin-left:5px;
				}
			}
		}
		.pagination-container {
			display: none;
		}
	}
	.sub_faq {
		margin-top: 30px;
	}
	.lst_faq {
		padding: 0 30px;
	}
	.lst_tb {
		.tb_tit {
			padding: 0 30px 0 10px;
		}
		.cont_item {
			padding: 0 30px 0 10px;
		}
	}
	.acc_header {
		height: 50px;
		padding: 0 70px 0 14px;
		.cate {
			font-size: 14px;
		}
		.tit {
			margin-left: 10px;
			font-size: 14px;
		}
		.btn_more {
			&:before {
				right: 18px;
			}
			&:after {
				width: 50px;
			}
		}
	}
	.acc_body {
		padding: 20px;
	}
	.evt_item {
		display: block;
		width: 100%;
		margin: 0 auto;
		padding: 30px 0 0;
		.thumb {
			position: relative;
			height: auto;
			padding-bottom: 49.785%;
			img {
				@include position(absolute, 0, null, null, 0);
			}
		}
	}
}
@media screen and (min-width: 640px) and (max-width: 1280px) {
	.evt_lst {
		margin: 0 75px;
	}
	.event {
		.lst_tb {
			.tit_item {
				&.tit_txt{
					max-width:50%;
				}
			}
		}
	}
} // 640
@media screen and (max-width: 640px) {
	.event {
		.filter_box {
			height: 70px;
			padding-left: 30px;
		}
	}
	.lst_tb {
		.tit_item {				
			&.tit_num {
				font-weight: 800;
			}
			&.tit_subject {
				padding-left: 20px;
				font-weight: 800;
			}
			&.tit_date {
				display: none;
			}
		}
		.tb_cont {
			.tit_box {
				width: 92.5%;
			}
			.date {
				display: none;
			}
			.num {
				font-weight: 800;
				color: $red;
			}
			.tit {
				padding-left: 20px;
			}
		}
	}
	.filter_lst {
		margin-left: 20px;
		.filter_item {
			&:not(:first-child) {
				.filter_lbl {
					min-width: 95px;
				}
			}
		}
	}
	.evt_lst {
		margin: 0 20px;
	}
}

@media screen and (max-width: 360px) {
	.evt_item {
		.txt_box {
			padding: 15px 7.9% 19px;
		}
		.cate {
			display: none;
		}
	}
}
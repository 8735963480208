/* SUB */

/* juinor book */

.sub_menu {
	margin-top: 21px;
	.tab_tit {
		@include tab-style(sub);
	}
}
.junior_wrap {
	.info_slide {
		position: relative;
		margin-top: 60px;
		padding: 30px 30px 120px;
		background-color: #fafafa;
	}
	.slide_content {
		overflow: hidden;
		width: 100%;
		height: 605px;
	}
	.slide_box {
		overflow: hidden;
		position: absolute;
		z-index: -1;
		width: 1140px;
		visibility: hidden;
		&:only-child, &.is_active {
			z-index: 1;
			visibility: visible;
		}
		.slide_item {
			.thumb {
				display: block;
				width: 1140px;
				height: 605px;
			}
			img {
				display: block;
				min-width: 100%;
				height: 100%;
			}
		}
	}
	.slide_bottom {
		display: flex;
		@include position(absolute, null, 0, 38px, 0);
		z-index: 1;
		height: 50px;
		padding: 0 70px 0 64px;
		.btn_link {
			@include position(absolute, null, 70px);
			height: 50px;
			padding: 14px 29px 0;
			@include btn-border(red);
			border-radius: 25px;
			font-size: 18px;
			font-weight: 800;
			letter-spacing: -.04em;
		}
		&.control_type {
			position: static;
			padding: 30px 0 0 40px;
			.btn_link {
				right: 40px;
			}
		}
	}
	.navi_box {
		margin-left: 64px;
		font-size: 0;
		[class^="btn"] {
			display: inline-block;
			position: relative;
			top: 0;
			left: 0;
			width: 47px;
			height: 47px;
			border-radius: 50%;
			background-image:none;
			transition: background .2s linear;
			opacity: 1;
			&:before {
				content: '';
				@include aln;
				width: 21px;
				height: 21px;
				background-size: 100% 100%;
			}
			&:last-child {
				margin-left: 12px;
			}
			&.btn_next {
				margin-top: 0;
				&:before {
					background-image: inline-svg(arr_right, $gray);
				}
				&:hover, &:focus {
					background-color: $red;
					&:before {
						background-image: inline-svg(arr_right, $white);
					}
				}
			}
			&.btn_prev {
				&:before {
					background-image: inline-svg(arr_left, $gray);
				}
				&:hover, &:focus {
					background-color: $red;
					&:before {
						background-image: inline-svg(arr_left, $white);
					}
				}
			}
		}
	}
	.paging_box {
		&:before {
			@include aln_m;
		}
		.paging_line {
			display: inline-block;
			position: static;
			width: 600px;
			margin-left: 53px;
		}
	}
	.info_section {
		padding-top: 50px;
		.title_img {
			&:before {
				content: '';
				display: block;
				margin: 0 auto;
				background-size: 100% 100%;
			}
		}
		.intro_box {
			text-align: center;
			.desc {
				font-size: 16px;
				font-weight: 400;
				color: $black;
				line-height: 29px;
				letter-spacing: -0.3px;
			}
			&.gf {
				.title_img {
					&:before {
						width: 280px;
						height: 173px;
						background-image: url(../img/logo_gf.png);
					}
				}
				.cont_tit {
					margin-top: 47px;
				}
				.desc {
					margin-top: 18px;
				}
			}
			&.bfs {
				.title_img {
					&:before {
						width: 280px;
						height: 174px;
						background-image: url(../img/logo_bfs.png);
					}
				}
				.cont_tit {
					margin-top: 36px;
				}
				.desc {
					margin-top: 18px;
				}
			}
			&.mgfg {
				.title_img {
					&:before {
						width: 142px;
						height: 179px;
						background-image: url(../img/logo_mgfg.png);
					}
				}
				.cont_tit {
					margin-top: 36px;
				}
				.desc {
					margin-top: 18px;
				}
			}
			&.junior {
				.title_img {
					&:before {
						width: 257px;
						height: 189px;
						background-image: url(../img/logo_junior.png);
					}
				}
				.cont_tit {
					margin-top: 30px;
				}
				.desc {
					margin-top: 18px;
				}
			}
			&.frs {
				.title_img {
					&:before {
						width: 200px;
						height: 201px;
						background-image: url(../img/logo_frs1.png);
					}
				}
				.cont_tit {
					margin-top: 30px;
				}
				.desc {
					margin-top: 18px;
				}
			}
			&.frs2 {
				.title_img {
					&:before {
						width: 200px;
						height: 201px;
						background-image: url(../img/logo_frs2.png);
					}
				}
				.cont_tit {
					margin-top: 30px;
				}
				.desc {
					margin-top: 18px;
				}
			}
			&.suptuntun {
				.title_img {
					&:before {
						width: 318px;
						height: 129px;
						background-image: url(../img/logo_stj1.png);
					}
				}
				.cont_tit {
					margin-top: 70px;
				}
				.desc {
					margin-top: 26px;
				}
			}
			&.suptuntun2 {
				.title_img {
					&:before {
						width: 318px;
						height: 129px;
						background-image: url(../img/logo_stj2.png);
					}
				}
				.cont_tit {
					margin-top: 70px;
				}
				.desc {
					margin-top: 26px;
				}
			}
			&.suptuntun3 {
				.title_img {
					&:before {
						width: 318px;
						height: 129px;
						background-image: url(../img/logo_stj3.png);
					}
				}
				.cont_tit {
					margin-top: 70px;
				}
				.desc {
					margin-top: 26px;
				}
			}
			&.info_home {
				&:after {
					display: none;
				}
				.tit_line {
					margin-bottom: 35px;
				}
			}
			&.learning_tool {
				.desc {
					margin: 24px 0 30px;
				}
			}
			.ico_ear {
				&:before {
					width: 35px;
					height: 50px;
					margin-top: -25px;
					@include sp-retina('ico_ear_rd');
				}
			}
			.ico_lip {
				&:before {
					width: 49px;
					height: 47px;
					margin-top: -28px;
					@include sp-retina('ico_lip_rd');
				}
			}
			.ico_pencil {
				&:before {
					width: 33px;
					height: 33px;
					margin-top: -16px;
					@include sp-retina('ico_pencil_rd');
				}
			}
		}
		.cont_tit {
			font-size: 22px;
			font-weight: 300;
			color: $red;
			letter-spacing: -.05em;
			.point {
				display: inline-block;
				margin-right: 7px;
				font-size: 26px;
				font-weight: 800;
				vertical-align: middle;
			}
			&.tit_line {
				display: inline-block;
				position: relative;
				padding: 0 11px 0 56px;
				font-size: 30px;
				font-weight: 800;
				&:after {
					content: '';
					@include position(absolute, null, 0, 0, 0);
					z-index: -1;
					width: 100%;
					height: 12px;
					border-radius: 6px;
					background-color: #fceaeb;
				}
			}
			&[class*="ico"] {
				&:before {
					content: '';
					@include position(absolute, 50%, null, null, 0);
				}
			}
		}
		.target {
			display: inline-block;
			margin-top: 30px;
			padding: 0 27px;
			height: 50px;
			border-radius: 25px;
			background: #fceaeb;
			font-weight: 400;
			.cate {
				display: inline-block;
				padding-right: 16px;
				font-weight: 800;
				color: $red;
				line-height: 50px;
				letter-spacing: -.036em;
			}
		}
		.tab_box {
			position: absolute;
			z-index: 300;
			display: inline-block;
			left: 50px;
			top: 50px;
		}
		.slide_tab {
			position: relative;
			overflow: hidden;
			width: 100%;
			font-size: 0;
		}
		.tab_item {
			display: inline-block;
			width: 180px;
			margin-left: 10px;
			&:first-child {
				margin-left: 0;
			}
			.btn_slide {
				color: #707070;
				height: 60px;
				font-size: 20px;
				line-height: 23px;
				letter-spacing: -0.4px;
				width: 100%;
				padding: 10px 0 10px;
				background-color: $gray;
				border-radius: 60px;
				font-weight: 800;
				span {
					display: block;
					font-size: 14px;
					line-height: 16px;
					letter-spacing: -0.28px;
				}
			}
			&.is_active {
				button {
					border: 2px dashed $white;
					background-color: $red;
					color: $white;
				}
			}
		}
		.main_option {
			overflow: hidden;
			position: relative;
			&.is_fixed {
				@include position(fixed, 0, 0, null, 0);
				z-index: 100;
				transition: all .3s ease;
				.main_option_lst {
					border-radius: 0;
				}
			}
		}
		.main_option_lst {
			.option_item {
				margin-left: 30px;	
				&:first-child {
		            margin-left: 0;
		        }			
				&.is_active {
					.option_link{
						letter-spacing: -.62px;
					}
				}
			}
			.option_link{
				font-size: 16px;
				letter-spacing: -0.32px;
			}
		}
	}
	.detail_section {
		.cont_box {
			position: relative;
			&:after {
				content: '';
				@include position(absolute, 0, 0, null, 0);
				height: 2px;
				background-color: #f4f4f4;
			}
			&:first-child {
				&:after {
					display: none;
				}
			}
			.cont_group {
				margin-top: 40px;
			}
			.num_circle {
				display: inline-block;
				font-size: 26px;
				line-height: 50px;
				letter-spacing: -0.52px;
				color: $white;
				background-color: $red;
				border-radius: 50%;
				padding: 8px 15px 2px;
				font-weight: 800;
			}
			.cont_tit {
				font-size: 24px;
				font-weight: 800;
				color: $red;
				letter-spacing: -.05em;
				text-align: center;
			}
			.sub_tit {
				font-size: 16px;
				font-weight: 400;
				color: $black;
				line-height: 26px;
				letter-spacing: -.03em;
				text-align: center;
				text-decoration: underline #707070;
				text-underline-position: under;
				.point {
					font-size: 20px;
					font-weight: 800;
				}
			}
			.book_tit {
				display: block;
				margin-bottom: 16px;
				font-size: 20px;
				font-weight: 800;
				line-height: 26px;
				letter-spacing: -0.4px;
				color: $red;
				text-align: left;
				border-bottom: 2px solid $red;
				span {
					position: relative;
					font-size: 16px;
					letter-spacing: -0.32px;
					font-weight: 400;
					margin-left: 8px;
				}
			}
			.desc {
				margin-top: 10px;
				font-size: 16px;
				font-weight: 400;
				color: $black;
				line-height: 1.63;
				letter-spacing: -.03em;
				word-break: keep-all;
				text-align: center;
			}
			.info_desc {
				display: inline-block;
				margin-top: 10px;
				padding: 6px 20px;
				border-radius: 14px;
				background-color: #f4f4f4;
				font-size: 14px;
				font-weight: 400;
				color: #707070;
				letter-spacing: -.03em;
				word-break: keep-all;
			}
			.tit_desc {
				font-size: 16px;
				line-height: 26px;
				letter-spacing: -0.32px;
				text-align: center;
				margin-top: 34px;
				.tit_point {
					font-weight: 800;
					color: $red;
				}
			}
			.img_lst {
				max-width: 1050px;
				margin: 0 auto;
				&.img_type {
					margin-top: -20px;
					.img_item {
						width: 180px;
						height: 180px;
						margin: 20px 10px 0 10px;
					}
				}
				&.img_type2 {
					margin-top: -20px;
					.img_item {
						width: 260px;
						height: 200px;
						margin: 20px 10px 0 10px;
					}
				}
				&.img_type3 {
					margin-top: -30px;
					.img_item {
						width: 300px;
						height: 170px;
						margin: 30px 10px 0 10px;
					}
				}
			}
			.cate_lst {
				margin-top: -40px;
				.cate_item {
					padding: 40px 24px 0;
					box-sizing: border-box;
					&.full {
						.thumb {
							height: 442px;
						}
					}
					.thumb {
						height: 280px;
						background-color: #f4f4f4;
					}
				}
			}
			.step_lst {
				display: flex;
				position: relative;
				width: 900px;
				height: 100%;
				margin: 0 auto;
				&:before {
					content: '';
					@include position(absolute, 0, 0, null, 0);
					width: 100%;
					height: 80px;
					border-radius: 40px;
					background: #fceaeb;
				}
			}
			.step_item {
				display: inline-block;
				position: relative;
				height: 100%;
				flex: 1;
				.tit {
					display: block;
					position: relative;
					height: 80px;
					padding-right: 22px;
					font-size: 22px;
					font-weight: 800;
					color: $red;
					line-height: 80px;
					letter-spacing: -.04em;
					text-align: center;
					&:after {
						content: '';
						@include position(absolute, 50%, 0);
						width: 22px;
						height: 42px;
						margin-top: -21px;
						background-image: inline-svg(arr2_right, $red);
						background-size: 100% 100%;
					}
				}
				&:last-child {
					.tit {
						padding-right: 0;
						&:after {
							display: none;
						}
					}
				}
			}
			.step_detail {
				margin-top: 22px;
				.detail_item {
					font-size: 18px;
					color: $red;
					letter-spacing: -.04em;
					line-height: 1.67;
					text-align: center;
				}
			}
			.num_lst {
				text-align: center;
				.num_item {
					margin-top: 20px;
					&:first-child {
						margin-top: 0;
					}
				}
				.txt_tit {
					font-size: 15px;
					letter-spacing: -0.3px;
					line-height: 26px;
					color: $black;
					background-color: #fceaeb;
					border-radius: 35px;
					padding: 17px 10px;
					max-width: 940px;
					margin: 0 auto;
					box-sizing: border-box;
					.num {
						display: inline-block;
						vertical-align: middle;
						font-size: 20px;
						line-height: 26px;
						letter-spacing: -0.4px;
						color: $red;
						font-weight: 800;
						margin-right: 4px;
					}
				}
			}
			.char_lst {
				padding: 26px 0 23px;
				font-size: 0;
				text-align: center;
				.detail_item {
					display: inline-block;
					margin-left: 60px;
					&:first-child {
						margin-left: 0;
					}
				}
				.story_name {
					display: block;
					font-size: 16px;
					line-height: 26px;
					letter-spacing: -0.32px;
					color: $black;
					font-weight: 800;
					margin-top: 17px;
				}
				.story_des {
					font-size: 14px;
					color: $black;
					letter-spacing: -0.28px;
					line-height: 18px;
					margin-top: 13px;
					min-height: 36px;
				}
			}
			.qa_detail {
				padding: 28px 0 11px;
				text-align: center;
				.story_tit {
					font-weight: 800;
				}
				.story_txt {
					font-size: 14px;
					color: $black;
					letter-spacing: -0.28px;
					line-height: 20px;
					margin-top: 13px;
				}
			}
			.vdo_lst {
				margin-top: -36px;
				font-size: 0;
				text-align: center;
			}
			.vdo_item {
				display: inline-block;
				position: relative;
				width: 500px;
				margin: 0 0 36px 48px;
				&:first-child {
					margin-left: 0;
				}
				.thumb {
					display: block;
					position: relative;
					width: 100%;
					height: 280px;
					&:before {
						content: '';
						@include position(absolute, 0, 0, null, 0);
						width: 100%;
						height: 100%;
						z-index: 1;
						border: 1px solid $gray;
						@include box-sizing(border-box);
					}
					img {
						display: block;
						width: 100%;
						height: 100%;
					}
				}
				.name {
					display: block;
					margin-top: 11px;
					font-weight: 400;
					font-size: 16px;
					letter-spacing: -.03em;
					color: $black;
					text-align: center;
					.point {
						margin-left: 5px;
						font-weight: 800;
					}
				}
				.btn_play {
					@include position(absolute, 0, 0, 0, 0);
					z-index: 1;
					width: 100%;
					height: calc(100% - 29px);
					&:before {
						content: '';
						@include aln;
						@include sp-retina('ico_play');
					}
				}
			}
			.btn_play {
				display: block;
			}
			.stage_lst {
				display: flex;
				justify-content: center;
				.stage_item {
					position: relative;
					margin-left: 90px;
					width: 190px;
					height: 190px;
					font-size: 20px;
					letter-spacing: -0.4px;
					line-height: 26px;
					color: $red;
					em {
						display: block;
						font-weight: 800;
					}
					&:before {
						content: '';
						display: block;
						width: 190px;
						height: 100%;
						margin: 0 auto;
						border-radius: 50%;
						background-color: #fceaeb;
					}
					.txt {
						@include aln;
						text-align: center;
					}
					&:after {
						content: '';
						@include position(absolute, 50%, -60px, null, null);
						width: 22px;
						height: 42px;
						margin-top: -21px;
						background-image: inline-svg(arr2_right, $red);
						background-size: 100% 100%;
					}
					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						&:after {
							display: none;
						}
					}
				}
			}
		}
		&.tool_section {
			.cont_box {
				&:last-child {
					&:after {
						display: none;
					}
				}
			}
		}
		.cate_tab {
			position: relative;
			max-width: 840px;
			margin: 0 auto;
			margin-top: 30px;
			text-align: center;
			font-size: 0;
			.cont_item {
				&.is_active {
					display: block;
					.tab_cont {
						display: block;
					}
				}
			}
			.tab_tit {
				display: flex;
			}
			.cate_tit {
				flex: 1;
				margin-left: 20px;
				.tit_link {
					display: block;
					color: #bebebe;
					font-size: 20px;
					height: 60px;
					letter-spacing: -0.4px;
					padding: 19px 0 18px;
					background-color: $gray;
					border-radius: 30px;
					font-weight: 800;
					box-sizing: border-box;
					transition: background-color 0.4s ease;
				}
				&:first-child {
					margin-left: 0;
				}
				&.is_active {
					.tit_link {
						background-color: $red;
						color: $white;
					}
				}
			}
			.item_tit {
				display: none;
			}
			.tab_cont {
				display: none;
			}
		}
		.info_cont {
			padding: 54px 0 51px;
			text-align: center;
			.cont_tit {
				margin-bottom: 20px;
			}
			.tab_view {
				display: none;
				&.is_active {
					display: block;
				}
			}
		}
		.feature_cont {
			padding: 54px 0 55px;
			text-align: center;
			.cont_tit {
				margin-bottom: 20px;
			}
			.txt_lst {
				display: inline-block;
			}
			.txt_item {
				text-align: left;
			}
		}
		.book_cont {
			padding: 64px 0 128px;
			.cont_tit {
				margin-bottom: 25px;
			}
			.sub_desc {
				margin-top: 10px;
			}
			.book_box {
				padding-top: 33px;
			}
		}
		.library_cont {
			padding: 54px 0 53px;
			.cate_box {
				margin-top: 25px;
			}
			.paging_num {
				display: none;
			}
		}
		.step_cont {
			padding: 60px 0 85px;
			.cont_tit {
				margin-bottom: 42px;
			}
		}
		.vdo_cont {
			padding-top: 54px;
			text-align: center;
			.cont_tit {
				margin-bottom: 40px;
			}
		}
		.story_cont {
			padding: 54px 0 56px;
			.cont_tit {
				margin-bottom: 31px;
			}
		}
		.program_cont {
			padding: 50px 0 60px;
			.cont_tit {
				margin-bottom: 30px;
			}
			.program_wrap {
				display: flex;
				justify-content: center;
				position: relative;
			}
			.tit_lst {
				align-self: flex-end;
				.tit_item {
					width: 80px;
					height: 80px;
					background-color: #f4f4f4;
					border-top-left-radius: 35px;
					border-bottom-left-radius: 35px;
					border-top-right-radius: 0;
					border-bottom: 1px solid $white;
					text-align: center;
					&:before {
						@include aln_m;
					}
				}
				.tit {
					display: inline-block;
					font-size: 14px;
					line-height: 16px;
					letter-spacing: -0.28px;
					font-weight: 800;
					vertical-align: middle;
				}
			}
			.prg_box {
				align-self: flex-start;
				.cont_lst {
					font-size: 0;
				}
			}
			.cont_item {
				display: inline-block;
				width: 220px;
				margin-left: 1px;
				&:first-child {
					margin-left: 0;
				}
				&:nth-child(even) {
					.detail_item {
						background-color: #fcfcfc;
					}
				}
				.tit {
					display: block;
					height: 100px;
					background-color: $red;
					text-align: center;
					border-radius: 30px 30px 0 0;
					box-sizing: border-box;
					color: $white;
					font-size: 14px;
					line-height: 17px;
					font-weight: 700;
					letter-spacing: -0.28px;
					&:before {
						@include aln_m;
					}
					em {
						display: block;
						font-size: 20px;
						font-weight: 800;
						line-height: 34px;
						letter-spacing: -0.4px;
					}
					.in {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
			.detail_item {
				height: 80px;
				border-bottom: 1px solid #f4f4f4;
				border-right: 1px solid #f4f4f4;
				background-color: $white;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: -0.28px;
				text-align: center;
				box-sizing: border-box;
				&:before {
					@include aln_m;
				}
				.in {
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
		.process_cont {
			padding: 52px 0 63px;
			text-align: center;
			.cont_tit {
				margin-bottom: 58px;
			}
			.process_lst {
				position: relative;
				font-size: 0;
				&:before {
					content: '';
					@include alnX;
					top: 79px;
					height: 221px;
					width: 38px;
					background-image: inline-svg(arr3_down, $red);
					background-size: 100% 100%;
				}
			}
			.process_item {
				display: inline-block;
				margin-left: 30px;
				.item_tit {
					display: inline-block;
					font-size: 26px;
					line-height: 60px;
					letter-spacing: -0.48px;
					font-weight: 800;
					color: $red;
					width: 400px;
					height: 60px;
					border-radius: 30px;
					border: 3px dashed #ffc4c7;
				}
				&:first-child {
					margin-left: 0;
				}
			}
			.class_lst {
				font-size: 0;
				text-align: center;
				margin-top: 40px;
				.class_item {
					display: inline-flex;
					flex-direction: column;
					justify-content: center;
					width: 160px;
					height: 160px;
					font-size: 16px;
					line-height: 20px;
					color: $red;
					letter-spacing: -0.32px;
					margin-left: 40px;
					background-color: #fceaeb;
					border-radius: 50%;
					&:first-child {
						margin-left: 0;
					}
					.tit {
						display: block;
					}
					.sub {
						position: relative;
						&:before {
							content: '';
							display: block;
							margin: 12px auto;
							width: 12px;
							height: 12px;
							background-image: inline-svg(arr_plus_rd);
							background-size: 100% 100%;
						}
					}
				}
			}
			.target {
				display: inline-block;
				width: 830px;
				background-color: $red;
				border-radius: 30px;
				font-size: 24px;
				line-height: 60px;
				letter-spacing: -0.48px;
				color: $white;
				height: 60px;
				text-align: center;
				margin-top: 60px;
			}
		}
		.book_info_cont {
			padding-top: 60px;
			.book_lst {
				font-size: 0;
				text-align: center;
			}
			.book_item {
				display: inline-block;
				position: relative;
				width: 300px;
				height: 500px;
				margin-left: 20px;
				text-align: center;
				box-sizing: border-box;
				&:before {
					content: '';
					@include position(absolute, -24px, null, null, 12px);
					z-index: -1;
					width: 111px;
					height: 109px;
					@include sp-retina('icon_crown_2');
					opacity: 0;
					transition: all .2s ease;
				}
				&:after {
					content: '';
					@include alnX;
					top: 0;
					z-index: -1;
					border: 20px solid #fceaeb;
					border-radius: 150px;
					width: 300px;
					height: 100%;
					transition: border-color .2s ease;
					box-sizing: border-box;
				}
				&:first-child {
					margin-left: 0;
				}
				&:hover,
				&:focus{
					&:before {
						z-index: 2;
						opacity: 1;
					}
					&:after {
						border-color: $red;
					}
				}
			}
			.logo_tit {
				overflow: hidden;
				display: block;
				margin: 0 auto;
				line-height: 999px;
				&:before {
					display: block;
					width: 100%;
					height: 100%;
				}
			}
			.gf {
				width: 130px;
				height: 93px;
				background: url(../img/logo_gf.png) no-repeat;
				background-size: 100% 100%;
			}
			.bfs {
				width: 130px;
				height: 93px;
				background: url(../img/logo_bfs.png) no-repeat;
				background-size: 100% 100%;
			}
			.mgfg {
				width: 90px;
				height: 93px;
				background: url(../img/logo_mgfg.png) no-repeat;
				background-size: 100% 100%;
			}
			.junior {
				width: 112px;
				height: 82px;
				background: url(../img/logo_junior.png) no-repeat;
				background-size: 100% 100%;
			}
			.suptuntun {
				width: 178px;
				height: 73px;
				background: url(../img/logo_stj1.png) no-repeat;
				background-size: 100% 100%;
			}
			.suptuntun2 {
				width: 178px;
				height: 73px;
				background: url(../img/logo_stj2.png) no-repeat;
				background-size: 100% 100%;
			}
			.suptuntun3 {
				width: 178px;
				height: 73px;
				background: url(../img/logo_stj3.png) no-repeat;
				background-size: 100% 100%;
			}
			.frs {
				width: 84px;
				height: 84px;
				background: url(../img/logo_frs1.png) no-repeat;
				background-size: 100% 100%;
			}
			.frs2 {
				width: 84px;
				height: 84px;
				background: url(../img/logo_frs2.png) no-repeat;
				background-size: 100% 100%;
			}
			.tit {
				display: block;
				font-size: 22px;
				font-weight: 800;
				color: $red;
				letter-spacing: -.04em;
			}
			.cate_tit {
				display: block;
				font-size: 18px;
				font-weight: 600;
				color: $red;
				letter-spacing: -.04em;
				&.tit_line {
					display: inline-block;
					&:after {
						content: '';
						display: block;
						width: 100%;
						height: 2px;
						margin-top: 12px;
						background-color: $red;
					}
				}
				.point {
					display: block;
					font-family: $pointFontFamily;
					font-size: 22px;
					font-weight: 600;
					color: $red;
					letter-spacing: -0.4px;
				}
			}
			.txt_desc {
				font-size: 16px;
				font-weight: 400;
				color: $red;
				line-height: 1.5;
				letter-spacing: -.03em;
			}
			.point_desc {
				display: inline-block;
				position: relative;
				padding: 9px 12px;
				font-family: $pointFontFamily;
				font-size: 15px;
				font-weight: 800;
				color: $red;
				line-height: 1.33;
				letter-spacing: -.03em;
				&:before {
					content: '';
					@include position(absolute, 0, 0, null, 0);
					height: 2px;
					background-color: $red;
				}
				&:after {
					content: '';
					@include position(absolute, null, 0, 0, 0);
					height: 2px;
					background-color: $red;
				}
			}
			.btn_link {
				@include position(absolute, null, 0, null, 0);
				@include btn-size(158px, 50px);
				margin: 0 auto;
				padding-top: 13px;
				@include btn-border(red);
				@include border-radius(25px);
				font-size: 18px;
				font-weight: 800;
				letter-spacing: -.04em;
			}
			.icon_lst {
				position: relative;
				margin-top: 10px;
				font-size: 0;
				&:before {
					content: '';
					@include position(absolute, 50%, null, null, 50%);
					width: 11px;
					height: 11px;
					margin-top: -5px;
					background-image: inline-svg(arr_plus_rd);
				}
			}
			.icon_item {
				display: inline-block;
				margin: 0 19px;
				vertical-align: middle;
				&.icon_eye {
					width: 44px;
					height: 25px;
					@include sp-retina('ico_eye');
				}
				&.icon_ear {
					width: 30px;
					height: 43px;
					@include sp-retina('ico_ear');
				}
				&.icon_hand {
					width: 33px;
					height: 41px;
					@include sp-retina('ico_hand');
				}
				&.icon_lip {
					width: 39px;
					height: 24px;
					@include sp-retina('ico_lip');
				}
			}
			.type_listening {
				.book_item {
					padding: 80px 0 130px;
				}
				.tit {
					margin-top: 13px;
				}
				.icon_lst {
					margin-top: 10px;
				}
				.txt_desc {
					margin-top: 18px;
				}
				.btn_link {
					bottom: 80px;
				}
			}
			.type_speaking {
				.book_item {
					padding: 70px 0 130px;
				}
				.cate_tit {
					margin-top: 5px;
				}
				.point_desc {
					margin-top: 17px;
				}
				.txt_desc {
					margin-top: 17px;
				}
				.btn_link {
					bottom: 80px;
				}
			}
			.type_literacy {
				.book_item {
					padding: 63px 0 130px;
				}
				.cate_tit {
					margin-top: 13px;
				}
				.txt_desc {
					@include alnY;
					left: 0;
					right: 0;
					padding: 232px 0 154px;
				}
				.btn_link {
					bottom: 80px;
				}
			}
			.type_immersion {
				.book_item {
					padding: 89px 0 160px;
				}
				.cate_tit {
					margin-top: 13px;
				}
				.txt_desc {
					@include alnY;
					left: 0;
					right: 0;
					margin-top: 12px;
				}
				.btn_link {
					bottom: 110px;
				}
			}
		}
		.tool_cont {
			padding: 70px 0 76px;
			text-align: center;
			&:first-child {
				padding-top: 100px;
			}
			.tool_tit {
				font-size: 22px;
				line-height: 36px;
				letter-spacing: -0.44px;
				color: $red;
				margin-top: 35px;
				font-weight: 300;
				.point {
					display: block;
					font-size: 36px;
					letter-spacing: -0.72px;
					font-weight: 800;
				}
			}
			.tool_desc {
				margin-top: 34px;
				font-size: 16px;
				line-height: 26px;
				letter-spacing: -0.32px;
			}
			.featured_lst {
				overflow: hidden;
				font-size: 0;
				margin: 23px 0 50px;
			}
			.featured_item {
				position: relative;
				display: inline-flex;
				margin-left: 96px;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.item_tit {
					display: inline-block;
					font-size: 18px;
					line-height: 26px;
					letter-spacing: -0.36px;
					font-weight: 800;
					color: $red;
					padding: 9px 20px 5px;
					border-radius: 25px;
					background-color: #fceaeb;
				}
				.item_desc {
					margin-top: 12px;
					font-size: 16px;
					line-height: 26px;
					letter-spacing: -0.32px;
				}
				&:first-child {
					margin-left: 0;
					&:after {
						content: '';
						position: absolute;
						display: inline-block;
						height: 80px;
						width: 1px;
						background-color: $red;
						top: 30px;
						right: -50px;
						transform: skew(-20deg);
					}
				}
			}
			.desc_lst {
				overflow: hidden;
				font-size: 0;
				margin: 54px 0 100px;
			}
			.desc_item {
				display: inline-block;
				margin-left: 50px;
				.thumb {
					display: block;
					width: 160px;
					height: 160px;
				}
				.item_desc {
					position: relative;
					margin-top: 14px;
					padding-left: 20px;
					font-size: 14px;
					line-height: 18px;
					letter-spacing: -0.28px;
					text-align: left;
				}
				.item_num {
					@include position(absolute, 0, null, null, 0);
					width: 16px;
					height: 16px;
					border-radius: 50%;
					background-color: #707070;
					font-size: 12px;
					font-weight: 800;
					color: $white;
					letter-spacing: -.02em;
					line-height: 18px;
					vertical-align: top;
					text-align: center;
				}
				&:first-child {
					margin-left: 0;
				}
			}
			.tool_thumb {
				position: relative;
				display: block;
				width: 587px;
				height: 477px;
				margin: 26px auto 40px;
				padding-top: 21px;
				box-sizing: border-box;
				&:after {
					content: '';
					@include position(absolute, 0, null, null, 0);
					z-index: -1;
					width: 100%;
					height: 100%;
					background: url(../img/bg_pc.png) no-repeat;
					background-size: 100% 100%;
				}
				img {
					display: block;
					max-width: 544px;
					height: 340px;
					margin: 0 auto;
				}
			}
			.option_lst {
				overflow: hidden;
				margin-top: 44px;
				text-align: center;
				font-size: 0;
				.btn_box {
					display: block;
					margin-top: 20px;
					font-size: 0;
					white-space: pre-wrap;
					.btn_link {
						min-width: 161px;
						&:first-child {
							margin-bottom: 8px;
						}
					}
				}
			}
			.option_item {
				display: inline-block;
				position: relative;
				padding: 0 45px;
				&:first-child {
					margin-left: 0;
					&:before {
						content: '';
						@include position(absolute, 30px, 0, 30px);
						width: 1px;
						background-color: #f4f4f4;
					}
				}
				.thumb {
					display: block;
					width: 140px;
					height: 140px;
					margin: 0 auto;
					img {
						display: block;
						width: 100%;
						height: 100%;
					}
				}
				.txt {
					display: block;
					font-size: 22px;
					line-height: 26px;
					letter-spacing: -0.44px;
					font-weight: 800;
					margin-top: 20px;
					.small_txt {
						font-size: 14px;
						letter-spacing: -0.28px;
						vertical-align: middle;
						font-weight: 400;
					}
				}
			}
			.btn_link {
				display: inline-block;
				height: 50px;
				padding: 13px 32px 0;
				@include btn-border(red);
				border-radius: 25px;
				font-size: 18px;
				font-weight: 800;
				letter-spacing: -.04em;
			}
		}
		.text_cont {
			.inner {
				position: relative;
				background-color: #fceaeb;
				padding: 39px 0 34px 224px;
				border-radius: 110px;
				box-sizing: border-box;
				&:before {
					content: '';
					@include position(absolute, 27px, null, null, 146px);
					width: 63px;
					height: 107px;
					background-image: inline-svg(ico_phone);
					background-size: 100% 100%;
				}
			}
			.tit {
				font-size: 22px;
				line-height: 26px;
				letter-spacing: -0.44px;
				font-weight: 800;
				color: $red;
				span {
					font-size: 14px;
					line-height: 26px;
					letter-spacing: -0.28px;
					font-weight: 400;
					color: $red;
				}
			}
			.txt {
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.32px;
				color: $red;
				margin-top: 13px;
			}
			.btn_link {
				@include position(absolute, 51px, 146px);
				z-index: 1;
				height: 50px;
				padding: 13px 32px 0;
				@include btn-border(red);
				border-radius: 25px;
				font-size: 18px;
				font-weight: 800;
				letter-spacing: -.04em;
			}
		}
	}
}
/* 
    * ~ 1280px
*/
@media screen and (max-width: 1279px) {
	.junior_wrap {
		.tab {
			display: none;
		}		
		.info_slide {
			margin-top: 0;
			padding: 0;
		}
		.slide_content {
			width: 100%;
			height: auto;
			padding-bottom: 66.67%;
		}
		.slide_box {
			width: 100%;
			height: 100%;
			.slide_item {
				overflow: hidden;
				.thumb {
					width: 100%;
					height: 100%;
					img {
						@include aln;
					}
				}
			}
		}
		.slide_bottom {
			left: auto;
			right: 10px;
			bottom: 10px;
			z-index: 1;
			height: auto;
			padding: 0;
			&.control_type {
				position: absolute;
			}
			.paging_line, 
			.navi_box,
			.btn_link {
				display: none !important;
			}
		}
		.info_section {
			position: relative;
			padding-top: 0;
			display: flex;
			flex-direction: column-reverse;
			.intro_box {
				position: relative;
				padding: 83px 40px 50px;
				&:after {
					content: '';
					@include position(absolute, null, 30px, 0, 30px);
					height: 2px;
					background-color: #f4f4f4;
				}
				.cont_tit {
					margin-top: 0 !important;
					font-size: 22px;
					line-height: 31px;
					.point {
						font-size: 26px;
					}
					.txt_type {
						margin-bottom: 8px;
						font-size: 18px;
						font-weight: 300;
						letter-spacing: -.04em;
					}
					&.tit_line {
						margin-bottom:24px;
						padding: 0;
						&:before, &:after {
							display: none;
						}
					}
				}
				.desc {
					line-height: 1.63;
				}
				.target {
					margin-top: 20px;
				}
				&.gf {
					.title_img {
						margin-top: calc((-173px * 0.78) / 2);
						&:before {
							width: calc(280px * 0.78);
							height: calc(173px * 0.78);
						}
					}
				}
				&.bfs {
					.title_img {
						margin-top: calc((-174px * 0.78) / 2);
						&:before {
							width: calc(280px * 0.78);
							height: calc(174px * 0.78);
						}
					}
				}
				&.mgfg {
					.title_img {
						margin-top: calc((-179px * 0.78) / 2);
						&:before {
							width: calc(142px * 0.78);
							height: calc(179px * 0.78);
						}
					}
				}
				&.junior {
					.title_img {
						margin-top: calc((-189px * 0.78) / 2);
						&:before {
							width: calc(257px * 0.78);
							height: calc(189px * 0.78);
						}
					}
				}
				&.frs {
					.title_img {
						margin-top: calc((-201px * 0.78) / 2);
						&:before {
							width: calc(200px * 0.78);
							height: calc(201px * 0.78);
						}
					}
				}
				&.frs2 {
					.title_img {
						margin-top: calc((-201px * 0.78) / 2);
						&:before {
							width: calc(200px * 0.78);
							height: calc(201px * 0.78);
						}
					}
				}
				&.suptuntun, &.suptuntun2, &.suptuntun3 {
					.title_img {
						margin-top: calc((-129px * 0.78) / 2);
						&:before {
							width: calc(318px * 0.78);
							height: calc(129px * 0.78);
						}
					}
				}
				&.learning_tool {
					padding: 33px 52px 40px;
					.desc {
						margin: 32px 0 0;
					}
				}
				.title_img {
					@include alnX;
					top: 0;
					z-index: 9;
				}
			}
			.tab_box, .main_option {
				display: none;
			}
		}
		.detail_section {
			.inner {
				width: 100%;
			}
			.cont_box {
				padding: 50px 30px;
				&:after {
					right: 30px;
					left: 30px;
				}
				.cont_group {
					margin-top: 20px;
				}
				.book_tit {
					display: none;
				}
				.desc {
					font-size: 14px;
					line-height: 22px;
				}
				.info_desc {
					line-height: 22px;
				}
				.txt_lst {
					.txt_item {
						font-size: 14px;
						line-height: 22px;
						&:before {
							top: 7px;
							margin-top: 0;
						}
					}
				}
				.cate_lst {
					display: flex;
					margin: 0;
					padding-bottom: 86px;
					.cate_item {
						position: relative;
						height: auto;
						margin-right: 20px;
						padding: 0;
						&.full {
							width: 50%;
							.thumb {
								height: auto;
							}
						}
						.cate {
							@include position(absolute, 100%, 0, null, 0);
							margin-top: 19px;
							text-align: center;
						}
						.thumb {
							position: relative;
							width: 100%;
							height: auto;
							padding-bottom: 63.27%;
						}
					}
				}
				.img_lst {
					display: flex;
					&.img_type {
						margin: 0;
						.img_item {
							margin: 0 20px 0 0;
						}
					}
					&.img_type2 {
						margin-top: 15px;
						.img_item {
							margin: 0 20px 0 0;
						}
					}
				}
				.vdo_lst {
					margin-top:0;
				}
				.vdo_item {
					position: relative;
					width: 50%;
					margin:0 0 40px;
					padding:0 10px;
					box-sizing:border-box;
					.thumb {
						position: relative;
						width: 100%;
						height: auto;
						padding-bottom: 73.325%;
						img {
							@include position(absolute, 0, 0, null, 0);
							width: 100%;
							height: 100%;
						}
					}
					.name {
						font-size: 14px;
						letter-spacing: -0.3px;
						text-align: center;
					}
				}
				.step_lst {
					width: 100%;
				}
				.step_item {
					.tit {
						font-size: 20px;
					}
				}
				.step_detail {
					.detail_item {
						font-size: 16px;
					}
				}
				.char_lst, .qa_detail {
					display: none;
				}
				.num_lst {
					.num_item {
						margin-top: 10px;
					}
					.txt_tit {
						background-color: $white;
						display: block;
						position: relative;
						text-align: left;
						padding: 0 0 0 16px;
						font-size: 14px;
						line-height: 22px;
						width: auto;
						word-break: keep-all;
						&:before {
							content: '';
							@include position(absolute, 7px, null, null, 0);
							width: 6px;
							height: 6px;
							border-radius: 50%;
							background-color: $red;
						}
						.num {
							display: none;
						}
					}
				}
				.stage_lst {
					padding-bottom: 35px;
					justify-content: flex-start;
					.stage_item {
						width: 100%;
						margin-left: 0;
						&:after {
							display: none;
						}
					}
				}
			}
			.book_cont {
				padding-bottom: 0;
				.book_box {
					margin-right: -30px;
					.paging_line {
						position: static;
						margin-top: 26px;
					}
				}
				.paging_line {
					display: block;
				}
			}
			.cate_tab {
				max-width: none;
				margin-top: 25px;
				padding-top: 0;
				.tab_tit {
					display: none;
					.tit_link {
						margin: 0 auto;
						background-color: $red;
						color: $white;
					}
				}
				.item_tit {
					display: block;
					font-size: 20px;
					width: 200px;
					height: 50px;
					margin: 0 auto;
					letter-spacing: -0.4px;
					padding: 15px 0 18px;
					background-color: $red;
					color: $white;
					border-radius: 30px;
					font-weight: 800;
					box-sizing: border-box;
				}
				.tab_cont {
					display: block;
					margin-top: 0;
					.cont_group {
						margin-top: 0;
					}
					.book_box {
						padding-top: 0;
					}
				}
				.cont_item {
					margin-top: 30px;
					&:first-child {
						margin-top: 0;
					}
				}
			}
			.info_cont {
				padding-left: 100px;
				padding-right: 100px;
			}
			.feature_cont {
				padding-left: 80px;
				padding-right: 80px;
			}
			.library_cont {
				padding-top: 63px;
				padding-bottom: 0;
				&:after {
					display: none;
				}
				.cate_box {
					margin-top: 30px;
				}
			}
			.step_cont {
				padding-top: 50px;
				padding-bottom: 50px;
				.cont_tit {
					margin-bottom: 40px;
				}
			}
			.vdo_cont {
				padding-top: 40px;
			}
			.process_cont {
				padding: 27px 0 61px;
				.cont_tit {
					margin-bottom: 40px;
				}
				.process_lst {
					&:before {
						display: none;
					}
				}
				.process_item {
					display: block;
					margin-left: 0;
					.item_tit {
						display: inline-block;
						width: 300px;
						height: 50px;
						border-radius: 25px;
						font-size: 24px;
						line-height: 50px;
					}
				}
				.class_lst {
					margin-top: 20px;
					.class_item {
						width: 145px;
						height: 145px;
						margin-left: 9px;
						font-size: 14px;
						line-height: 18px;
					}
				}
				.target {
					width: 300px;
					height: 50px;
					line-height: 50px;
					margin-top: 20px;
				}
				.paging {
					position: static;
					margin-top: 20px;
				}
			}
			.program_cont {
				padding: 38px 30px 44px 30px;
				.prg_box {
					margin: 0;
				}
				.tit_lst {
					padding-bottom: 26px;
				}
				.detail_lst {
					padding-bottom: 26px;
				}
				.cont_lst {
					width: 440px;
				}
				.cont_tit {
					margin-bottom: 25px;
				}
				.cont_item {
					margin-left: 0;
				}
				.paging {
					bottom: 0;
				}
			}
			.book_info_cont {
				padding: 0;
				width: 300px;
				height: 500px;
				padding-bottom: 26px;
				&:before {
					content: '';
					@include position(absolute, 0, 0, null, 0);
					z-index: -1;
					width: 300px;
					height: 500px;
					border: 20px solid #fceaeb;
					border-radius: 150px;
					box-sizing: border-box;
				}
				.book_item {
					width: 260px;
					height: auto;
					margin-left: 0;					
					&:after {
						display: none;
					}
				}
				.paging {
					position: static;
					margin-top: 20px;
				}
			}
			.tool_cont {
				margin-top: 0;
				padding-bottom: 40px;
				&:first-child {
					margin-top: 0;
					padding-top: 40px;
				}
				&:nth-child(2) {
					.info_slide {
						margin-top: 23px;
					}
				}
				.tool_tit {
					margin-top: 20px;
					font-size: 18px;
					line-height: 1.4;
					.point {
						font-size: 24px;
					}
				}
				.tool_tit {
					font-size: 18px;
				}
				.tool_desc{
					margin-top:23px;
					font-size: 14px;
					line-height:22px;
				}
				.featured_lst {
					margin: 30px 0 43px;
				}
				.featured_item {
					display: flex;
					flex-direction: column;
					margin-top: 34px;
					margin-left: 0;
					.desc {
						font-size: 14px;
						letter-spacing: -0.28px;
						line-height: 22px;
					}
					&:first-child {
						margin-top: 0;
						&:after {
							display: none;
						}
					}
				}
				.desc_lst {
					display: none;
				}
				.option_lst {
					margin-top:30px;
					.btn_box {
						margin-left: 24px;
						.btn_link {
							min-width: 130px;
						}
					}
				}
				.option_item {
					display: flex;
					padding: 52px 0 0;
					flex-direction: row;
					justify-content: center;
					align-items: flex-start;
					&:first-child {
						padding-top: 0;
						&:before {
							top: auto;
							bottom: -26px;
							left: 0;
							right: 0;
							width: 100%;
							height: 2px;
						}
					}
					.thumb{
						width:110px;
						height:110px;
					}
					.txt {
						margin-top: 10px;
						font-size: 18px;
						line-height: 21px;
					}
					.small_txt {
						display: block;
					}
				}
				.btn_link {
					height: 40px;
					padding-top:10px;
					padding-bottom:10px;
					border-radius: 20px;
					font-size: 14px;
				}
			}
			.text_cont {
				.inner {
					display: flex;
					width: 260px;
					height: 260px;
					margin: 0 auto;
					padding: 0;
					border-radius: 50%;
					flex-direction: column;
					align-items: center;
					&:before {
						position: static;
						width: 52px;
						height: 89px;
						margin-top: -10px;
					}
				}
				.tit {
					margin-top: 15px;
					font-size: 22px;
				}
				.txt {
					display: none;
				}
				.btn_link {
					position: static;
					margin-top: 14px;
				}
			}
		}
	}
}
/* 
    * ~ 640px
*/
@media screen and (max-width: 639px) {
	.junior_wrap{
		.info_section {
			.intro_box {
				padding-left: 30px;
				padding-right: 30px;
				&.info_home {
					padding-top: 30px;
				}
				.cont_tit {
					line-height: 31px;
					.point {
						display: block;
						margin-right: 0;
					}
				}
				.desc {
					font-size: 14px;
					line-height: 1.57;
				}
			}
			.slide_content {
				padding-bottom: 100%;
			}
		}
		.detail_section {
			.cont_box {
				.cate_lst {
					.cate_item {
						width: 100%;
						padding: 0;
						margin-right: 0 !important;
						&.full {
							width: 100%;
							margin-right: 0;
						}
					}
				}
				.vdo_item {
					display: block;
					width: 100%;					
					margin-left: 0;
					.name {
						position: static;
					}
				}
				.step_lst {
					flex-direction: column;
					align-items: center;
					&:before {
						display: none;
					}
				}
				.step_item {
					&:after {
						content: '';
						display: block;
						width: 22px;
						height: 42px;
						margin: 28px auto 25px;
						background-image: inline-svg(arr2_right, $red);
						background-size: 100% 100%;
						transform: rotate(90deg);
					}
					&:last-child {
						&:after {
							display: none;
						}
					}
					.tit {
						width: 240px;
						margin: 0 auto;
						padding-right: 0;
						border-radius: 40px;
						background: #fceaeb;
						&:after {
							display: none;
						}
					}
				}
				.step_detail {
					margin-top: 16px;
					font-size: 0;
					text-align: center;
					.detail_item {
						display: inline-block;
						&:last-child {
							&:before {
								content: '/';
								display: inline-block;
								margin: 0 3px;
							}
						}
					}
				}
			}
			.info_cont {
				padding-left: 40px;
				padding-right: 40px;
			}
			.feature_cont {
				padding-left: 40px;
				padding-right: 40px;
			}
			.library_cont {
				padding-top: 40px;
				padding-right: 30px;
				.paging_num {
					display: block;
					@include position(absolute, null, 30px, 51px, auto);
				}
			}
			.program_cont {
				.cont_lst {
					width: 220px;
				}
			}
			.tool_cont {
				.tool_thumb {
					width: 100%;
					height: auto;
					margin-bottom: 0;
					padding: 0 0 84%;
					img {
						@include position(absolute, 4.3%, 0, null, 0);
						z-index: -1;
						width: 93%;
						height: 71%;
					}
				}
			}
		}
	}
}

/* 
    * ~ 360px
*/
@media screen and (max-width: 360px) {
	.event_section{
		.tit_box{
			.h_tit{
				word-break: keep-all;
				word-wrap: break-word;
			}
		}
	}
	.junior_wrap{
		.info_section{
			.intro_box{
				.desc{
					word-break: keep-all;
					word-wrap: break-word;
					br{
						display:none;
					}
				}
			}
		}
	}
}
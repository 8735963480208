/* Nanum Square Round */
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 300;
  src:url("../font/NanumSquareRoundL.woff") format("woff");
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 400;
  src:url("../font/NanumSquareRoundR.woff") format("woff");
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 700;
  src:url("../font/NanumSquareRoundB.woff") format("woff");
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 800;
  src:url("../font/NanumSquareRoundEB.woff") format("woff");
}

/* SDIyagi */
@font-face {
    font-family: "SDIyagi";
    src:url('../font/SDIyagi-Lt.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "SDIyagi";
    src:url('../font/SDIyagi-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "SDIyagi";
    src:url('../font/SDIyagi-Bd.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "SDIyagi";
    src:url('../font/SDIyagi-ExBd.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

/* BreeSerif */
@font-face {
  font-family: "BreeSerif";
  src:url('../font/BreeSerif-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
$normal-vars: (
	'ico_blog': (
		offset-x: -728px,
		offset-y: -493px,
		width: 24px,
		height: 11px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_cafe': (
		offset-x: -581px,
		offset-y: -290px,
		width: 22px,
		height: 10px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_facebook': (
		offset-x: -767px,
		offset-y: -258px,
		width: 8px,
		height: 15px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_idea': (
		offset-x: -448px,
		offset-y: -205px,
		width: 81px,
		height: 110px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_kakao': (
		offset-x: -533px,
		offset-y: -290px,
		width: 20px,
		height: 19px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_more': (
		offset-x: -760px,
		offset-y: -474px,
		width: 15px,
		height: 15px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_play': (
		offset-x: -728px,
		offset-y: -455px,
		width: 28px,
		height: 34px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_plus': (
		offset-x: -760px,
		offset-y: -455px,
		width: 16px,
		height: 15px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'ico_youtube': (
		offset-x: -557px,
		offset-y: -290px,
		width: 20px,
		height: 14px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'icon_crown': (
		offset-x: -533px,
		offset-y: -205px,
		width: 93px,
		height: 81px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'icon_crown2': (
		offset-x: -652px,
		offset-y: -258px,
		width: 111px,
		height: 109px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'icon_girl': (
		offset-x: -652px,
		offset-y: -455px,
		width: 72px,
		height: 51px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'img_book': (
		offset-x: 0px,
		offset-y: -331px,
		width: 189px,
		height: 177px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'img_book_sm': (
		offset-x: -164px,
		offset-y: -177px,
		width: 147px,
		height: 146px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'img_girl': (
		offset-x: -652px,
		offset-y: -371px,
		width: 113px,
		height: 80px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'img_phone': (
		offset-x: 0px,
		offset-y: 0px,
		width: 160px,
		height: 327px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'img_phone_sm': (
		offset-x: -652px,
		offset-y: 0px,
		width: 124px,
		height: 254px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo_fstread': (
		offset-x: -448px,
		offset-y: 0px,
		width: 200px,
		height: 201px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo_gf': (
		offset-x: -164px,
		offset-y: 0px,
		width: 280px,
		height: 173px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo_junior': (
		offset-x: -315px,
		offset-y: -177px,
		width: 126px,
		height: 47px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
	'logo_tuntun': (
		offset-x: -315px,
		offset-y: -228px,
		width: 94px,
		height: 32px,
		total-width: 776px,
		total-height: 508px,
		imageSrc: '../img/sprites/normal.png'
	),
);
$normal-origin: (
  total-width: 776px,
  total-height: 508px,
  imageSrc: '../img/sprites/normal.png'
);

@mixin sprite-size($image) {
	background-size: map-get($image, 'total-width') map-get($image, 'total-height');
}

@mixin sprite-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-normal {
  @include sprite-size($normal-origin);
  @include sprite-image($normal-origin);
}

@mixin sp-normal($image, $size: true) {
  @include set-normal;
	background-position: map-get(map-get($normal-vars, $image), 'offset-x') map-get(map-get($normal-vars, $image), 'offset-y');
	@if $size {
		width: map-get(map-get($normal-vars, $image), 'width');
		height: map-get(map-get($normal-vars, $image), 'height');
	}
}

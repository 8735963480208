/* SUB */

/* junior info */
// bg때문에 width: 100%이여야 하는 경우
.inner_wrap {
    &.wide {
        width: 100%;
        padding-bottom: 0;
        .breadcrumbs_box {
            max-width: 1200px;
            margin: 0 auto;
        }
    }
}

.intro_wrap {
    .eng {
        font-family: $engPointFontFamily;
    }
    // custom
    .inner_wrap {
        [class*='tit'] {
            font-family: $pointFontFamily;
            color: #dc0012;
            font-weight: 700;
        }
        .page_tit {
            margin-bottom: 16px;
            letter-spacing: -0.6px;
        }
    }
    .page_desc {
        margin-bottom: 36px;
        color: #dc0012;
        letter-spacing: -0.3px;
    }
    .main_option {
        margin-bottom: 48px;
        text-align: center;

        .main_option_lst {
            justify-content: center;
            
            .option_item {
                position: relative;
                width: auto;
                min-width: 80px;
                padding: 0 4.8%;

                a {
                    font-size: 20px;
                    letter-spacing: -0.4px;
                }
                &.swiper-slide-thumb-active {
                    a {
                        color: $red;
                        font-weight: 800;
                    }
                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background-color: $red;
                        transition: all .3s ease;
                        content:'';
                    }
                }
            }
            .option_item:not(:first-child) {
                margin-left: 6%;
            }
        }
    }

    /* info */
    // section
    .section {
        padding: 50px 0 50px;
        text-align: center;
    }
    .bg_section {
        @extend .section;
        background-color: #fef6f7;

        &:last-child {
            padding-bottom: 123px;
        }
    }
    .top_line{
        position: relative;
        padding: 40px 0 50px;
        text-align: center;

        &::before {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 1000px;
            height: 2px;
            background-color: #ddd;
            content: '';
        }

        &.bg_making_story {
            >div {
                padding-bottom: 222px;
                background: url(../img/junior_info/bg_making_story@2x.png) no-repeat left 50% bottom 50px / 427px auto;
            }
        }
    }

    // title
    .tit {
        display: block;
        margin-bottom: 15px;        
        font-size: 28px;        
        line-height: 1.1;        
        letter-spacing: -0.6px;
    }
    .sub_tit {
        display: block;
        margin-bottom: 13px;
        font-size: 24px;
        line-height: 1.25;
        letter-spacing: -0.5px;

        &.big {
            position: relative;
            margin-bottom: 30px;
            padding-bottom: 30px;

            &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100px;
                height: 2px;
                background-color: #ffc4c7;
                content: '';
            }
            span {
                display: block;

                &.num {
                    margin-bottom: 16px;
                    font-family: $engPointFontFamily;
                    font-size: 50px;
                    line-height: 1.12;
                }
                &:not(.num) {
                    margin-bottom: 4px;
                    font-weight: 300;
                }
            }
            em {
                font-family: $engPointFontFamily;
                font-size: 28px;
                font-weight: 400;
                line-height: 1.07;
                letter-spacing: -0.6px;
            }
        }

        &.head_tit {
            font-family: $pointFontFamily;
            font-size: 22px;
            font-weight: 500;
            line-height: 1.09;
            color: #dc0012;
            letter-spacing: -0.4px;

            a {
                display: block;
                color: inherit;
            }
            span {
                position: relative;
                display: inline-block;
                margin-right: 10px;
                padding-right: 15px;
                font-weight: 300;
                vertical-align: text-bottom;

                &::before {
                    position:absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 15px;
                    background-color: #dc0012;
                    content: '';
                }
                &:nth-of-type(2) {
                    overflow: hidden;
                    display: inline-block;
                    margin-top: 4px;
                    margin-left: 5px;
                    padding: 0 10px;
                    font-size: 11px;
                    line-height: 1.73;
                    text-align: center;
                    vertical-align: top;
                    color: #fff;
                    letter-spacing: -0.2px;
                    border-radius: 30px;
                    background-color: #dc0012;
                }
            }
            em {
                font-family: $engPointFontFamily;
                font-weight: 400;
                vertical-align: text-bottom;
            }
        }
    }
    .sub_tit2 {
        font-size: 22px;
        line-height: 1.5;
        letter-spacing: -0.4px;
    }
    .em {
        display: block;
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.4px;
    }

    // text
    .desc {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.3px;
        color: #1a1311;
    }

    // button
    .btn {
        display: inline-block;
        border-radius: 30px;
    }
    .btn_link {
        padding: 0 47px;
        font-weight: 700;
        line-height: 46px;

        @include btn-border(red);

        span {
            position: absolute;
        }
    }
    .btn_more {
        padding: 0 52px;
        font-weight: 700;
        line-height: 46px;

        @include btn-bg(light_gray);
        
        &.only_360 {
            margin: 40px 0 0;
        }
    }

    // color
    .c_red {
        color: $red;
    }

    // list
    .list {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.3px;
        color: #dc0012;

        + .desc {
            font-weight: 700;
        }
    }
    .list_step {
        max-width: 800px;
        margin: 0 auto;

        li {
            margin-bottom: 20px;
            padding: 38px 6.25%;
            color: $red;
            text-align: left;
            border-radius: 20px;
            box-sizing: border-box;
            
            &:nth-child(1) {
                background-color: $light_pink;
            }
            &:nth-child(2) {
                background-color: $pink;
            }
            &:nth-child(3) {
                background-color: #ffc4c7;
            }
            &:nth-child(4) {
                color: #fff;
                background-color: $red;

                div {
                    &::before {
                        background-color: #fff;
                    }
                    .btn {
                        border-color: #fff;
                    }
                }
            }

            h5, div {
                display: inline-block;
                vertical-align: middle;
            }
            h5 {
                width: 135px;
                font-family: $engPointFontFamily;
                font-size: 24px;
                line-height: 1.25;

                span {
                    display: block;
                    font-size: 17px;
                    line-height: 1.7;
                    letter-spacing: -0.3px;
                }
            }
            div {
                position: relative;
                width: calc(100% - 141px);
                padding: 0 160px 0 5.8%;
                box-sizing: border-box;

                &::before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: 2px;
                    height: 64px;
                    background-color: $red;
                    content: '';
                }

                em {
                    display: block;
                    margin-bottom: 7px;
                    font-family: $pointFontFamily;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 1.25;
                    letter-spacing: -0.5px;
                }
                p {
                    font-size: 14px;
                    line-height: 1.3;
                    letter-spacing: -0.3px;
                }
                .btn {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    padding-right: 6.2%;
                    padding-left: 6.2%;
                }
            }
        }
    }
    .list_step2 {
        max-width: 800px;
        margin: 10px auto 24px;
        text-align: center;
        color: $red;

        li {
            display: inline-block;
            width: 27.875%;
            max-width: 223px;
            height: 82px;
            margin: 0 5px;
            padding: 21px 6.3% 15px;
            font-family: $engPointFontFamily;
            font-size: 22px;
            line-height: 30px;
            border-radius: 20px;
            background-color: #ffeae9;
            box-sizing: border-box;

            span {
                display: block;
                font-size: 16px;
                line-height: 1;
            }
            em {
                display: block;
            }
        }
    }
    .list_bg {
        max-width: 912px;
        margin: 36px auto 0;

        li {
            display: table;
            position: relative;
            min-height: 216px;
            padding: 40px calc(5.9% + 186px) 40px 9.6%;
            text-align: left;
            color: $red;
            border-radius: 200px;
            background-color: #fceaeb;
            box-sizing: border-box;

            &:not(:last-child) {
                margin-bottom: 40px;
            }

            img {
                position: absolute;
                top: 50%;
                right: 5.9%;
                transform: translateY(-50%);
                width: 156px;
            }
            div {
                display: table-cell;
                vertical-align: middle;
                
                em {
                    display: inline-block;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 1.25;
                    vertical-align: middle;
                    letter-spacing: -0.5px;

                    &.eng {
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 1.36;
                        letter-spacing: -0.4px;
                    }
                }
                .ellipse {
                    background-color: #fff;
                }
                p {
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 1.43;
                    letter-spacing: -0.3px;
                }
            }
        }
    }
    .list_circle {
        margin: 0 auto;

        li {
            display: inline-block;
            overflow: hidden;
            text-align: center;
            letter-spacing: -0.4px;
            vertical-align: top;
        }
        &.simple {
            max-width: 730px;
            margin: 37px auto 0;
            li {
                position: relative;
                width: 210px;
                height: 210px;
                font-size: 20px;
                font-weight: 700;
                line-height: 1.4;
                color: #fff;
                border-radius: 50%;
                background-color: #dc0012;

                &:not(:nth-child(3n-2)) {
                    margin-left: 5.23%;
                }
                p {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 100%;
                    padding: 0 20px;
                    font-weight: 400;
                    box-sizing: border-box;
                }
                em {
                    display: block;
                    font-weight: 700;
                }
            }
        }
        &.multi {
            max-width: 670px;
            text-align: left;
            background: url(../img/icon_crown_3@2x.png) no-repeat right 27px top 470px / 134px auto;

            li {
                width: 190px;
                height: 190px;
                font-size: 18px;
                line-height: 1.44;

                &:not(:nth-child(3n-2)) {
                    margin-left: 6.7%;
                }
                &:nth-child(3) ~ li {
                    margin-top: 30px;
                }

                a {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    color: #dc0012;
                    background: url(../img/) no-repeat 50% 85px;
                    
                    &:hover, &:focus {
                        >span:nth-child(2) {
                            opacity: 1;
                            color: #fff;
                            background-color: #dc0012;
                            transition: all .4s ease;
                        }
                    }
                    
                    span {
                        display: block;
                        width: 100%;
                        box-sizing: border-box;
                    }
                    >span {
                        height: 100%;
                        padding: 70px 0 0;
                        border-radius: 50%;

                        &:nth-child(1) {
                            border: 2px solid #dc0012;

                            span {
                                font-size: 14px;
                                font-weight: 800;
                                letter-spacing: -0.3px;
                            }
                        }
                        &:nth-child(2) {
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            padding: 70px 0 27px;

                            em {
                                display: inline-block;
                                margin: 15px 0 0;
                                font-size: 14px;
                                line-height: 1.43;
                                letter-spacing: 0.3px;
                                border-bottom: 1px solid #fff;
                            }
                        }
                    }
                }
            }
            & + .btn_more {
                display: none;
            }
        }
    }

    .ellipse {
        display: inline-block;
        padding: 0 10px;
        font-size: 13px;
        font-weight: 700;
        text-align: center;
        line-height: 1.55;
        vertical-align: middle;
        letter-spacing: -0.3px;
        color: $red;
        border-radius: 10px;
    }

    // box
    .quote_box {
        position: relative;
        padding: 13px 0 18px 32px;
        background: url(../img/junior_info/quote@2x.png) no-repeat 0 0 / 25px auto;
        box-sizing: border-box;

        &::after {
            display: inline-block;
            width: 25px;
            height: 18px;
            background: url(../img/junior_info/quote_2@2x.png) no-repeat 0 90% / 25px auto;
            content: '';
        }
    }

    // img
    .img_box {
        display: block;
        margin: 34px auto 57px;

        &.fix_width {
            display: inline-block;
            max-width: 545px;
            margin-bottom: 56px;

            img {
                position: relative;

                &:nth-child(1) {
                    left: 0;
                    max-width: 300px;
                    margin-top: 7px;
                }
                &:nth-child(2) {
                    right: -50px;
                    width: 240px;
                }
            }
        }

        &.the_best {
            margin-bottom: 25px;
            text-align: center;
            div{
                margin-bottom: 40px;
            }
            img {
                width: 240px;
                vertical-align: bottom;

                &:not(:first-child) {
                    margin-left: 1.9%;
                }
            }
        }
    }

    // tab contents
    .tab_contents_slide {
        .swiper-slide, .swiper-wrapper {
            height: auto;
        }
    }

    // navi
    .navi_box {
        @include position(absolute,null,0,0,0);

        > a {
            top: auto;
            bottom: 0;
            width: 128px;
            height: 15px;
            margin: 0;
        }
        .btn_prev {
            left: 0;
        }
        .btn_next {
            left: auto;
            right: 0;
        }
        .swiper-button-disabled {
            span:not(.blind) {
                display: none;
            }
        }
    }

    // paging
    .paging {
        position: static;
        padding: 45px 0 0;
    }

    // making sotry
    .mgfg_story_box {
        max-width: 871px;
        margin: 0 auto 35px;
        padding: 30px 0 0;
        text-align: left;

        .show_video {
            position: relative;
            margin-bottom: 40px;

            > div {
                &:nth-child(1) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 385px;
                    height: 385px;
                    margin: 0 0 16px;
                    padding: 6px 7% 0 8%;
                    color: #fff;
                    border-radius: 50%;
                    background-color: $red;
                    box-sizing: border-box;

                    &::before {
                        position: absolute;
                        top:0;
                        left: 36px;
                        width: 67px;
                        height: 59px;
                        background: url(../img/sprites/retina/icon_crown_2.png) no-repeat 0 0 / contain;
                        content: '';
                    }
                    em {
                        display: flex;
                        padding: 12px 0;
                        font-family: $pointFontFamily;
                        font-size: 21px;
                        font-weight: 700;
                        line-height: 1.14;
                        letter-spacing: -0.4px;

                        .is_pc {
                            display: inline;
                        }
                    }
                    p {
                        margin-bottom: 12px;
                        font-size: 14px;
                        line-height: 1.43;
                        letter-spacing: -0.3px;

                        em {
                            display: block;
                            margin-bottom: 18px;
                            font-size: 13px;
                            font-weight: 800;
                            line-height: 1.85;
                            border-top: 1px solid #fff;
                            border-bottom: 1px solid #fff;
                        }
                    }
                    .btn_play {
                        width: 160px;
                        padding: 13px 65px 13px 30px;
                        font-size: 16px;
                        line-height: 1.5;
                        text-align: left;
                        letter-spacing: -0.3px;
                        @include btn-border(red);
                        &:after{
                            @include position(absolute,50%);
                            width:36px;
                            height:8px;
                            margin:-6px 0 0 8px;
                            background-image:inline-svg(arr3_right_sm, $red);
                            background-repeat:no-repeat;
                            background-size:100% 100%;
                            content:'';
                        }
                        &:hover,
                        &:focus{
                            border-color:$white;
                            &:after{
                                background-image:inline-svg(arr3_right_sm, $white);
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    position: absolute;
                    left: 41.4%;
                    bottom: -16px;
                    width: 313px;

                    img {
                        width: 100%;
                    }
                }
                &:nth-child(3) {
                    position: absolute;
                    top: 20px;
                    right: 0;
                    z-index: -1;
                    width: 252px;
                }

                img {
                    max-width: 100%;
                }
            }
        }
        .who {
            padding: 0 6.3%;
            div, >p {
                display: inline-block;
                vertical-align: top;
            }
            div {
                width: 57%;
                color: $red;

                em {
                    font-family: $pointFontFamily;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 1.36;
                    vertical-align: middle;
                    letter-spacing: -0.4px;
                }
                .ellipse {
                    margin-left: 5px;
                    background-color: #ffc4c7;
                }
                p {
                    margin: 10px 0 0;
                    font-family: $engPointFontFamily;
                    font-size: 18px;
                    line-height: 1.33;
                    letter-spacing: 0.2px;
                }
            }
            >p {
                width: calc(43% - 6px);
                vertical-align: top;
                font-size: 14px;
                line-height: 1.43;
                letter-spacing: -0.3px;
                color: $red;

                &::after {
                    margin: 0 0 -10px 5px;
                }
            }
        }
    }
    .making_story {
        .swiper-slide-duplicate {
            display: none;
        }
        .navi_box, .paging_num {
            display: none;
        }
    }

    .best_box {
        padding-top: 77px;
        text-align: center;
        background: url(../img/icon_crown_3@2x.png) no-repeat 50% 0 / 70px auto;
    
        h4 {
            margin-bottom: 20px;

            em {
                font-family: $engPointFontFamily;
            }
        }
        .desc {
            margin-bottom: 25px;
            color: $red;
    
            em {
                font-weight: 800;
            }
        }
        .btn {
            font-family: $pointFontFamily;
            border-radius: 50px;
            
            &:not(.change_mo) {                
                min-width: 270px;
                padding: 15px 6.1%;
                font-size: 24px;
                @include btn-border(red);
            }
    
            &.change_mo {
                padding: 20px 5%;
                color: #fff;
                background-color: #dc0012;
    
                span {
                    display: inline-block;
                    font-weight: 300;
                    line-height: 1.29;
                    vertical-align: middle;
                    letter-spacing: -0.3px;
                }
                em {
                    display: inline-block;
                    margin-left: 15px;
                    font-family: $pointFontFamily;
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 1;
                    vertical-align: middle;
                    letter-spacing: -0.6px;
                }
            }
        }
    }

    // junior effect
    .effect_slide {
        max-width: 900px;
        margin-bottom: 50px;
        color: #dc0012;

        .paging {
            position: absolute;
            bottom: 0;
        }
    }
    
    .student_item {
        margin-bottom: 45px;
        text-align: center;
    
        >button {
            display: inline-block;
            width: 240px;
            vertical-align: bottom;
    
            img {
                max-width: 100%;
            }
        }
        >div {
            display: inline-block;
            margin-left: 5.4%;
            text-align: left;
            vertical-align: middle;
    
            dl {
                display: inline-block;
                vertical-align: top;
                em {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    font-size: 16px;
                    line-height: 30px;
                    text-align: center;
                    vertical-align: top;
                    letter-spacing: -0.3px;
                    border-radius: 50%;
                    background-color: #fceaeb;
                    box-sizing: border-box;
                }
            }
            p {
                margin-bottom: 18px;
                font-size: 15px;
                line-height: 22px;
                color: #1a1311;
                letter-spacing: -0.3px;
    
                >em {
                    display: inline-block;
                    margin-bottom: 17px;
                    padding-bottom: 2px;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 20px;
                    color: #dc0012;
                    letter-spacing: -0.4px;
                    border-bottom: 1px solid #dc0012;
                }
                span {
                    display: block;
                }
            }
            .btn_play {            
                position: relative;
                padding: 13px 27px 13px 50px;
                font-size: 16px;
                font-weight: 800;
                line-height: 1.5;
                letter-spacing: -0.3px;
                border-radius: 30px;
    
                @include btn-border(red);
    
                &::before {
                    position: absolute;
                    top: 50%;
                    left: 30px;
                    transform: translateY(-50%) rotate(270deg);
                    width: 0;
                    height: 0;
                    border: 0 solid #fff;
                    border-top-color: #dc0012;
                    border-right-width: 6px;
                    border-left-width: 6px;
                    border-top-width: 10px;
                    content: '';
                }
                &:hover::before,
                &:focus::before {
                    border-color: #dc0012;
                    border-top-color: #fff;
                    transition: 0.4s border-top-color ease-out 0s;
                }
            }
        }
    }
    
    .study_level {
        overflow: hidden;
        margin-bottom: 45px;
        border: 2px solid #fceaeb;
        border-radius: 20px;

        & + .study_level {
            margin-top: 20px;
        }    
        .sub_tit {
            padding: 15px 6.1%;
            background-color: #fceaeb;
        }
        div {
            padding: 17px 0;
        }
        .study_logo {
            display: inline-block;
            position: relative;
            width: 45.5%;
            text-align: center;
            vertical-align: middle;
    
            li {
                display: inline-block;
                max-width: 110px;
                min-width: 85px;
                padding: 0 1.25%;
                box-sizing: border-box;
                
                &.gf {
                    em::before {
                        background: url(../img/logo_gf_2@2x.png) no-repeat 50% 3px / contain;
                    }
                }
                &.bfs {
                    em::before {
                        background: url(../img/logo_bfs.png) no-repeat 50% 2px / contain;
                    }
                }
                &.mgfg {
                    em::before {
                        background: url(../img/logo_mgfg_2@2x.png) no-repeat 50% 0 / contain;
                    }
                }
                &.junior {
                    em::before {
                        background: url(../img/logo_junior_2@2x.png) no-repeat 50% 0 / contain;
                    }
                }
                &.frs1 {
                    em::before {
                        background: url(../img/logo_frs1.png) no-repeat 50% 0 / contain;
                    }
                }
                &.frs2 {
                    em::before {
                        background: url(../img/logo_frs2.png) no-repeat 50% 0 / contain;
                    }
                }
                &.stj1 {
                    em::before {
                        background: url(../img/logo_stj1@2x.png) no-repeat 50% 0 / contain;
                    }
                }
                &.stj2 {
                    em::before {
                        background: url(../img/logo_stj2@2x.png) no-repeat 50% 0 / contain;
                    }
                }
                &.stj3 {
                    em::before {
                        background: url(../img/logo_stj3@2x.png) no-repeat 50% 0 / contain;
                    }
                }
                em {
                    display: block;
                    position: relative;
                    padding-top: 61px;
                    font-size: 10px;
                    line-height: 1.2;
                    letter-spacing: -0.2px;
    
                    &::before {
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        height: 56px;
                        margin-bottom: 5px;
                        content: '';
                    }
                }
            }
            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 52px;
                background-color: #dc0012;
                content: '';
            }
        }
    
        .study_txt {
            display: inline-block;
            width: 53.8%;
            padding: 0 3.8%;
            font-size: 14px;
            line-height: 1.36;
            vertical-align: middle;
            letter-spacing: -0.3px;
            box-sizing: border-box;
    
            em {
                display: block;
                margin-bottom: 4px;
                font-size: 16px;
                font-weight: 800;
                line-height: 1.5;
            }
        }
    }
}

// 1280
@media screen and (max-width: 1279px) {
    .intro_wrap {
        .grade_item, .section, .bg_section, .top_line {
            padding-right: 2.5%;
            padding-left: 2.5%;
            box-sizing: border-box;
        }

        .sub_tit {
            &.big {
                span {
                    &:not(.num) {
                        font-size: 22px;
                    }
                }
            }
        }

        .list_circle {
            &.simple {
                li {
                    width: 180px;
                    height: 180px;
    
                    &:not(:nth-child(3n-2)) {
                        margin-left: 3.23%;
                    }
                }
            }
            &.multi {
                max-width: 575px;
                background-position: right 20px top 440px;

                li {
                    width: 175px;
                    height: 175px;
                    &:not(:nth-child(3n-2)) {
                        margin-left: 3.5%;
                    }
                    a {
                        >span:nth-child(1) {
                            padding: 65px 0 0;
                        }
                    }
                }
            }
        }

        // img
        .img_box {
            &.the_best {
                img {
                    width: 180px;
                }
            }
        }

        // making sotry
        .mgfg_story_box {
            max-width: 673px;

            .show_video {
                > div {
                    &:nth-child(1) {
                        padding-right: 8%;
                    }
                    &:nth-child(2) {
                        left: auto;
                        right: 0;
                    }
                    &:nth-child(3) {
                        display: none;
                    }
                }
            }
        }

        .study_level {
            .study_logo {
                width: 47.5%;
    
                li {
                    width: calc((100% - 11px) / 3);
                }
            }
        
            .study_txt {
                width: 51.8%;
                padding: 0 3%;
            }
        }

        // navi
        .navi_box {
            .btn_prev {
                left: 2.5%;
            }
            .btn_next {
                right: 2.5%;
            }
        }
    }
}

// 640
@media screen and (max-width: 639px) {
    .intro_wrap {
        // custom
        .inner_wrap {
            .page_tit {
                margin-bottom: 25px;
            }
        }
        .inner_wrap:not(.wide) {
            padding-bottom: 150px;
        }
        .page_desc {
            margin-bottom: 30px;
        }
        .main_option {
            margin-bottom: 35px;
    
            .main_option_lst {
                height: 40px;
                background-color: #fff;

                .option_item {
                    padding: 0 7px;
    
                    .option_link {
                        font-size: 18px;
                        letter-spacing: -0.36px;
                    }
                }
                .option_item:not(:first-child) {
                    margin-left: 6%;
                }
            }
    
        }

        /* info */
        // section
        .grade_item, .section, .bg_section, .top_line {
            padding-right: 6.94%;
            padding-left: 6.94%;
        }
        .bg_section {
            &:last-child {
                padding-bottom: 132px;
            }
        }
        .top_line {
            margin-top: -40px;

            &::before {
                display: none;
            }
            &.bg_making_story {
                >div {
                    padding: 40px 7.5% 168px;
                    text-align: left;
                    border-radius: 30px;
                    background-color: #fdf6f6;
                    background-size: 239px auto;
                }
                .desc {
                    font-size: 14px;
                }
            }
        }

        // title
        .tit {
            font-size: 24px;
            line-height: 1.2;
        }
        .sub_tit {
            &.m_line {
                position: relative;
                padding-top: 20px;
                text-align: left;
    
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 185px;
                    height: 2px;
                    background-color: $red;
                    content: '';
                }
            }
        }
        .sub_tit2 {
            font-size: 18px;
            line-height: 1.33;
        }

        // list
        .list_step {
            max-width: 800px;
            margin: 0 auto;
    
            li {
                position: relative;
                margin-bottom: 30px;
                padding: 30px 7.7% 33px;

                &:not(:last-child)::after {
                    position: absolute;
                    top: 93%;
                    right: 40px;
                    z-index: -1;
                    width: 0;
                    height: 0;
                    border: 20px solid rgba(255,255,255,0);
                    border-top-color: $light_pink;
                    border-top-width: 35px;
                    border-right-width: 20px;
                    border-bottom-width: 35px;
                    content: '';
                }
                &:nth-child(2) {
                    &::after {
                        border-top-color: $pink;
                    }
                }
                &:nth-child(3) {
                    &::after {
                        border-top-color: #ffc4c7;
                    }
                }


                h5, div {
                    width: 100%;
                }
                div {
                    margin-top: 14px;
                    padding: 20px 0 0;
    
                    &::before {
                        top: 0;
                        transform: translate(0);
                        width: 100%;
                        height: 2px;
                    }
    
                    p {
                        margin-bottom: 23px;
                        line-height: 1.43;
                    }
                    .btn {
                        position: static;
                        transform: translate(0);
                    }
                }
            }
        }
        .list_step2 {
            margin: 30px auto;
            li {
                display: block;
                width: 223px;
                max-width: auto;
                margin: 0 auto 10px;
            }
        }
        .list_bg {
            margin: 30px auto 0;

            li {
                padding: 30px 9.8%;
                text-align: center;
                border-radius: 30px;

                &:not(:last-child) {
                    margin-bottom: 0
                }

                img {
                    position: static;
                    transform: translate(0);
                    margin-bottom: 10px;
                }
                div {
                    display: block;
                    
                    .ellipse {
                        background: none;
                    }
                    p {
                        text-align: left;
                    }
                }
            }
        }
        .list_circle {
            &.simple {
                li {
                    display: block;
                    
                    &:nth-child(n) {
                        margin: 0 auto;
                    }
                    &:not(:last-child) {
                        margin-bottom: 30px;
                    }
                }
            }
            &.multi {
                background: none;
                
                li {
                    display: block;
                    width: auto;
                    height: auto;

                    &:nth-child(3) ~ li {
                        display: none;
                    }
                    &:not(:nth-child(3n-2)) {
                        margin-left: 0;
                    }
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
    
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover, &:focus {
                            >span:nth-child(2) {
                                opacity: 1;
                                color: #dc0012;
                                background-color: #fff;
                                transition: none;
                            }
                        }

                        >span {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            vertical-align: middle;
                            
                            &:nth-child(1) {
                                width: 140px;
                                height: 140px;
                                padding: 0 16px;
                                color: #fff;
                                background: #dc0012;
                            }
                            &:nth-child(2) {
                                opacity: 1;
                                position: static;
                                width: calc(100% - 140px);
                                max-width: 170px;
                                padding: 0 0 0 15px;
                                word-break: keep-all;
                                word-wrap: break-word;
    
                                em {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                & + .btn_more {
                    display: inline-block;
                    width: 160px;
                }
            }
        }

        // box
        .quote_box {
            padding: 27px 0;

            &::after {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        // img
        .img_box {
            margin-top: 30px auto 50px;
            &.fix_width {
                max-width: 300px;
                margin: 30px auto -40px;
    
                img {    
                    &:nth-child(1) {
                        max-width: 100%;
                        margin-top: 0;
                    }
                    &:nth-child(2) {
                        top: -80px;
                    }
                }
            }

            &.the_best {
                div {
                    position: relative;
                    max-width: 310px;
                    height: 314px;
                    margin: 0 auto 64px;
                }
                img {
                    position: absolute;

                    &:nth-child(1) {
                        top: 125px;
                        left: 0;
                        width: 155px;
                    }
                    &:nth-child(2) {
                        left: 31.6%;
                        width: 164px;
                        margin: 0;
                    }
                    &:nth-child(3) {
                        bottom: 0;
                        right: 0;
                        width: 173px;
                        margin: 0;
                    }
                }
            }
        }

        // making sotry
        .mgfg_story {
            .sub_tit {
                width: 82%;
                margin:0 7.5%;
            }
        }
        .mgfg_story_box {
            margin: 0 auto 10px;
            
            .show_video {
                max-width: 280px;
                margin: 0 auto;

                > div {
                    &:nth-child(1) {
                        width: 250px;
                        height: 250px;
                        margin: 0;
                        padding: 0 14%;

                        &::before {
                            left: 24px;
                            width: 42px;
                            height: 38px;
                        }
                        em {
                            padding: 5px 0;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 1.07;
                            letter-spacing: -0.3px;
                        }
                        p {
                            margin-bottom: 0;
                            line-height: 1.29;

                            em {
                                margin-bottom: 10px;
                                font-size: 11px;
                                font-weight: 700;
                                line-height: 1.18;

                                .is_mo_sm {
                                    display: inline;
                                }
                            }
                        }
                        .btn_play {
                            display: none;
                        }
                    }
                    &:nth-child(2) {
                        position: relative;
                        top: -40px;
                        left: 64px;
                        bottom: auto;
                        width: 217px;
                    }
                }
            }
            .who {
                padding: 0 6.3%;
                div, >p {
                    width: 100%;
                }
                div {
                    margin-bottom: 20px;

                    p {
                        margin: 15px 0 0;
                        font-size: 15px;
                        line-height: 1.47;
                    }
                }
                >p {
                    &::after {
                        margin:0;
                    }
                }
            }
        }
        .making_story {
            text-align: left;

            .swiper-slide-duplicate {
                display: block;
            }
            .navi_box {
                display: block;
                a {
                    width: 186px;
                }
                .btn_next {
                    span {
                        padding-left: 0;
                    }
                }
            }            
            .paging_num {
                display: inline-block;
                height: auto;
                margin: 15px 20px 0;
                font-size: 14px;
                line-height: 1.71;
                letter-spacing: -0.3px;
                background: none;
            }
        }

        .effect_slide {
            margin-bottom: 45px;

            .paging {
                position: static;
                margin: -20px 0 50px;
                padding: 0;
            }
        }
        
        .student_item {
            margin-bottom: 40px;
    
            >button {
                margin-bottom: 25px;
            }
            >div {
                display: block;
                margin-left: 0;
                text-align: center;
                p {
                    margin-bottom: 26px;
                    font-size: 14px;
    
                    >em {
                        font-size: 16px;
                        letter-spacing: -0.32px;
                    }
                }
                .btn_play {
                    padding: 16px 29px;
        
                    &::before {
                        display: none;
                    }
                }
            }
        }
    
        .study_level {
            margin-bottom: 0;
            text-align: center;

            .head_tit {
                padding: 0;
                a {
                    padding: 15px 6.1%;
                }
            }
            div {
                padding: 18px 5%;
            }
            .study_logo {
                width: 100%;
                margin-bottom: 20px;
                padding-bottom: 17px;
        
                li {
                    padding: 0 2%;
                }
                &::after {
                    top: 100%;
                    right: 0;
                    transform: translate(0);
                    width: 100%;
                    height: 1px;
                }
            }        
            .study_txt {
                width: 100%;
                padding: 0;
                font-size: 14px;
        
                em {
                    display: block;
                    margin-bottom: 15px;
                    font-size: 16px;
                    font-weight: 800;
                    line-height: 1.38;
                }
            }
        }
    
        .best-box {
            padding-top: 70px;
            background-size: 50px auto;
        
            h4 {
                margin-bottom: 25px;
            }
            .btn {    
                &.change_mo {
                    padding: 16px 14.4%;
                    color: #dc0012;
                    background-color: #fff;
                    @include btn-border(red);
        
                    em {
                        margin-left: 0;
                        font-size: 24px;
                        font-weight: 700;
                    }
                }
            }
        }

        // navi
        .navi_box {
            display: none;
            .btn_prev {
                left: 6.94%;
            }
            .btn_next {
                right: 6.94%;
            }
        }

        .best_box {
            .btn {
                &.change_mo {
                    width: 270px;
                    @include btn-border(red);

                    em {
                        margin: 0;
                    }
                }
            }
        }
    }

}

// 360
@media screen and (max-width: 360px) {
    .intro_wrap {
        .grade_item, .section, .bg_section, .top_line {
            padding-right: 3%;
            padding-left: 3%;
        }

        .sub_tit {
            font-size: 22px;
            &.big {
                span {
                    &:not(.num) {
                        font-size: 20px;
                    }
                }
                em {
                    font-size: 24px;
                }
            }
        }

        .study_level {
            .study_logo {
                li {
                    width: calc((100% - 11px) / 3);

                    &.gf {
                        em::before {
                            background-size: 70px auto;
                        }
                    }
                    &.bfs {
                        em::before {
                            background-size: 75px auto;
                        }
                    }
                    &.mgfg {
                        em::before {
                            background-size: 45px auto;
                        }
                    }
                }
            }
        }
    }
}
/* SCSS */
/* Common */
@import "common/reset.scss";
@import "common/font.scss";
/* Mixin */
@import "mixins/mixins.scss";
@import "mixins/tab.scss";
@import "mixins/button.scss";

/* SVG Icons */
@import "svg/sass-inline-svg-data.scss";
@import "svg/sass-inline-svg.scss";
@import "svg/svgicons.scss";
/* Sprites */
@import "../sprites-data/normal.scss";
@import "../sprites-data/retina.scss";
/* Common */
@import "common/variable.scss";
@import "common/common.scss";
/* Component */
@import "component/button.scss";
@import "component/tab.scss";
@import "component/list.scss";
/* Pages */
@import "pages/main.scss";
@import "pages/layer.scss";
@import "pages/junior_book.scss";
@import "pages/junior_info.scss";
@import "pages/edu_center.scss";
@import "pages/experience.scss";
@import "pages/tuntun_info.scss";

@import "pages/guide.scss"; // 최종 산출물 공유 시 삭제
$retina-vars: (
	'ico_blog': (
		offset-x: -829px,
		offset-y: -383px,
		width: 24px,
		height: 11px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_book_rd': (
		offset-x: -667px,
		offset-y: -228px,
		width: 45px,
		height: 32px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_cafe': (
		offset-x: -820px,
		offset-y: -481px,
		width: 22px,
		height: 10px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_ear': (
		offset-x: -240px,
		offset-y: -229px,
		width: 30px,
		height: 43px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_ear_rd': (
		offset-x: -820px,
		offset-y: -427px,
		width: 35px,
		height: 50px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_eye': (
		offset-x: -732px,
		offset-y: -504px,
		width: 44px,
		height: 25px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_facebook': (
		offset-x: -842px,
		offset-y: -504px,
		width: 8px,
		height: 15px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_hand': (
		offset-x: -294px,
		offset-y: -178px,
		width: 33px,
		height: 41px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_kakao': (
		offset-x: -829px,
		offset-y: -342px,
		width: 20px,
		height: 19px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_lip': (
		offset-x: -780px,
		offset-y: -504px,
		width: 39px,
		height: 24px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_lip_rd': (
		offset-x: -667px,
		offset-y: -177px,
		width: 59px,
		height: 47px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_more': (
		offset-x: -823px,
		offset-y: -504px,
		width: 15px,
		height: 15px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_pencil_rd': (
		offset-x: -331px,
		offset-y: -178px,
		width: 33px,
		height: 33px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_phone': (
		offset-x: -599px,
		offset-y: -177px,
		width: 64px,
		height: 108px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_play': (
		offset-x: -274px,
		offset-y: -229px,
		width: 28px,
		height: 34px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_plus': (
		offset-x: -829px,
		offset-y: -398px,
		width: 16px,
		height: 15px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'ico_youtube': (
		offset-x: -829px,
		offset-y: -365px,
		width: 20px,
		height: 14px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'icon_crown': (
		offset-x: -732px,
		offset-y: -342px,
		width: 93px,
		height: 81px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'icon_crown_2': (
		offset-x: -732px,
		offset-y: -427px,
		width: 84px,
		height: 73px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'icon_girl': (
		offset-x: -164px,
		offset-y: -229px,
		width: 72px,
		height: 51px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'img_book': (
		offset-x: -204px,
		offset-y: -331px,
		width: 189px,
		height: 177px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'img_book_sm': (
		offset-x: -448px,
		offset-y: -177px,
		width: 147px,
		height: 146px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'img_girl': (
		offset-x: -732px,
		offset-y: -258px,
		width: 113px,
		height: 80px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'img_phone': (
		offset-x: 0px,
		offset-y: 0px,
		width: 160px,
		height: 327px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'img_phone_sm': (
		offset-x: -732px,
		offset-y: 0px,
		width: 124px,
		height: 254px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'logo_bfs': (
		offset-x: -164px,
		offset-y: 0px,
		width: 280px,
		height: 174px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'logo_fstread': (
		offset-x: 0px,
		offset-y: -331px,
		width: 200px,
		height: 201px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'logo_gf': (
		offset-x: -448px,
		offset-y: 0px,
		width: 280px,
		height: 173px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'logo_junior': (
		offset-x: -164px,
		offset-y: -178px,
		width: 126px,
		height: 47px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'logo_mgfg': (
		offset-x: 0px,
		offset-y: -536px,
		width: 142px,
		height: 179px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
	'logo_tuntun': (
		offset-x: -599px,
		offset-y: -289px,
		width: 94px,
		height: 32px,
		total-width: 856px,
		total-height: 715px,
		imageSrc: '../img/sprites/retina.png'
	),
);
$retina-origin: (
  total-width: 856px,
  total-height: 715px,
  imageSrc: '../img/sprites/retina.png'
);

@mixin sprite-size($image) {
	background-size: map-get($image, 'total-width') map-get($image, 'total-height');
}

@mixin sprite-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

%set-retina {
  background-repeat: no-repeat;
  @include sprite-image($retina-origin);
  @media
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (   min--moz-device-pixel-ratio: 2),
		only screen and (     -o-min-device-pixel-ratio: 2/1),
		only screen and (        min-device-pixel-ratio: 2),
		only screen and (                min-resolution: 192dpi),
		only screen and (                min-resolution: 2dppx) {
      @include sprite-size($retina-origin);
      @include sprite-image-retina($retina-origin);
	}
}

@mixin sp-retina($image, $size: true) {
  @extend %set-retina;
	background-position: map-get(map-get($retina-vars, $image), 'offset-x') map-get(map-get($retina-vars, $image), 'offset-y');
	@if $size {
		width: map-get(map-get($retina-vars, $image), 'width');
		height: map-get(map-get($retina-vars, $image), 'height');
	}
}

 $tab: tab;
 $active: is_active;
.#{$tab}{
    position:relative;   
    &_tit{
        position:relative;   
        .tit_item{
            overflow:hidden;
            height: 63px;
            border:2px solid transparent;        
            border-width:2px 0 0;
            border-bottom:2px solid $red;
            border-radius:16px 16px 0 0;   
            opacity:1;                   
            box-sizing:border-box;
            &.is_active{
                border-width:2px 2px 0; 
                border-color:$red;
                background-color:$white !important;  
                .tit_link{
                    font-weight:600;      
                }
                .sub_lst{
                    display:block;
                }
            }
            // ,&.swiper-slide-active
            &.swiper-slide-thumb-active{
                border-width:2px 2px 0; 
                border-color:$red;
                background-color:$white !important;  
                .tit_link{
                    font-weight:600;      
                }
                .sub_lst{
                    display:block;
                }
            }
        }
        .tit_link{
            display:block;   
            position: relative;
            z-index:10; 
            height:100%;                 
            color:$red;            
            text-align:center;            
            box-sizing:border-box;
        }
    }
    &_cont{
        .cont_lst{
        }
        .cont_link{
        }
    }
    .sub_lst{
        display:none;
        @include position(absolute,null,0,0,0);
        height:60px;
        padding:0 66px;
        background-color:#f4f4f4;
        .sub_item{
            display:inline-block;    
            height:100%;            
            &:before{
                @include aln_m;
            }
            &:after{
                content:'';
                display:inline-block;
                width:1px;
                height:10px;
                margin:0 30px;
                background-color:$red;
                vertical-align:middle;
            }      
            &:last-child{
                &:after{
                    display:none;
                }
            }   
            &.is_active{
                .sub_link{
                    font-weight:800;
                }
            }
        }
        .sub_link{
            display:inline-block;
            font-size:16px;
            color:$red;
        }
    }
    .has_sub{

    }
    &_scroll{
        overflow-x:auto;
    }
}

// 1280
@media screen and (max-width: 1279px) {
    // .tab{
    //     .tit_lst{
    //         justify-content: normal;
    //     }
    // }
}
//640
@media screen and (max-width: 639px){
    // .tit_item{
    //     &.swiper-slide-active{
    //         border-width:2px 2px 0; 
    //         border-color:$red;
    //         background-color:$white !important;  
    //         .tit_link{
    //             font-weight:600;      
    //         }
    //         .sub_lst{
    //             display:block;
    //         }
    //     }
    //     &.swiper-slide-thumb-active{
    //         border:none;
    //         background:none;
    //         .tit_link{
    //             font-weight:normal;      
    //         }
    //         .sub_lst{
    //             display:none;
    //         }
    //     }
    // }
    // .tab_tit{
    //     padding-left:40%;
    // }
    // .tit_lst{
    //     margin-left:40%;
    // }
}
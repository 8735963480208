/* SUB */

/* Experience common */
.experience_wrap{
	.page_desc{
		margin-top:32px;
	}
	.sub_ex{
		margin-top:46px;
		@include tab-style(sub);
	}
	.tab_cont_area{
		padding-top:60px;
		.application{
			padding-bottom:36.5px;
			border-bottom:2px solid #f4f4f4;
			text-align:center;
			font-size:0;
		}
		.thumb,.txt_box{
			display:inline-block;
			font-size:16px;
			vertical-align:middle;
		}
		.thumb{
			width:220px;
		}
		img{
			width:100%;
			vertical-align:top;
		}
		.txt_box{
			margin-left:30px;
			text-align:left;
			color:#dc0012;
		}
		.txt_box{
			.tit{
				font-size:30px;
				font-family:$pointFontFamily;
				font-weight:700;
			}
			.sub_tit{
				display:block;
				margin-top:8px;
				font-size:22px;
				font-family:$pointFontFamily;
				font-weight:500;
				br{
					display:none;
				}
			}
			.desc{
				margin-top:17px;
				line-height:1.5;
			}
		}
		.info_desc{
			display:inline-block;
			margin-top:11px;
			padding:5px 14px 5px 12px;
			border-radius:13px;
			background-color:#fceaeb;
			font-size:14px;
			&:after{
				display:block;
				clear:both;
				content:'';
			}
			.info_tit,.info_txt{
				float:left;
			}
			.info_tit{
				&:before{
					margin-right:3px;
					content:'*';
					vertical-align:middle;
				}
			}
			.info_txt{
				&:before{
					margin:0 3px;
					content:':';
				}
			}
		}
	}
	
	.accept{
		margin-top:43.5px;
		padding-top:46.5px;
		border-top:2px solid #f4f4f4;
		.tit{
			font-size:30px;
			color:#dc0012;
			font-family:$pointFontFamily;
			font-weight:700;
		}
		.checkbox_area{
			margin-top:20px;
			padding:25px 20px 20px;
			border-radius:20px;
			background-color:#fceaeb;
			box-sizing:border-box;
			.lab_chx{
				color:$red;
			}
		}
		.sub_agree{		
			border-bottom:1px solid #fcd8da;
	        &:first-child{
	            padding-bottom:26px;
	            border-color:$red;
	        }
			&:last-child{
				border-bottom:none;
				.sub_agree_tit{
					padding:15px 0 0 0;
				}
			}
	        .sub_agree_tit{
	            position:relative;
	            padding:15px 0;
	            .btn_more{
	                overflow:hidden;
	                position: absolute;
	                top:8px;
	                right:8px;
	                width:32px;
	                height:32px;
	                &:before{
	                	content:'';
	                	@include aln;
	                	width:10px;
		                height:6px;
		                background-image:inline-svg(arr4_down, $red);
		                background-size:100% 100%;
	                }
	            }
	            &.is_active{
	                padding:15px 0;
	                border-bottom:0;
	                +.sub_agree_cont{
	                    display:block;
	                    margin-bottom: -1px;
	                }
	                .btn_more{
	                	&:before{
	                		background-image:inline-svg(arr4_up, $red);
	                	}
	                }
	            }
	        }	
			.sub_agree_cont{
				display:none;
				height:234px;
				background-color:#fff;
				color:#1a1311;
				font-size:13px;
				line-height:1.38;
				.agree_item{
					&:first-child{
						margin-top:0;
					}
				}
				.sub_lst{
					margin-top:13px;
				}
				li,
				p{
					margin-top:6px;
				}
			}
		}
		& + .btn_area{
			margin-top:40px;
			font-size:0;
			[class^="btn"]{
				display:inline-block;
				width:240px;
				height:60px;
				border-radius:60px;
				font-size:18px;
				font-weight:800;
				vertical-align:middle;
			}
			.btn_back{
				padding-top:18px;
				@include btn-border(gray2);
			}
			.btn_link{
				@include btn-border(red);
				margin-left:19.5px;
			}
		}
	}
	.btn_area{
	    margin-top:30px;
	    text-align:center;
	}
	.step{
		padding-top:37.5px;
	}
	
	.step_inner{
		max-width:540px;
		margin:0 auto;
		padding:0 20px;
		box-sizing:border-box;
		.step_tit{
			display:block;
			width:109px;
			height:40px;
			margin-left:-20px;
			border-radius:20px;
			background-color:#fceaeb;
			color:#dc0012;
			font-size:18px;
			font-weight:800;
			text-align:center;
			&:before{
				@include aln-m;
			}
		}
		.step_desc{
			margin-top:9px;
			color:#dc0012;
			font-family:$pointFontFamily;
			font-size:35px;
			font-weight:300;
			line-height:1.26;
			.point{
				font-size:30px;
				font-weight:700;
				line-height:1.67;
			}
		}
	}
	.step_form{
		margin-top:40px;
		.tit{
			color:#dc0012;
			font-weight:700;
			&:not(:first-of-type){
				margin-top:30px;
			}
		}
		.txt{
			margin-top:10px;
		}
	}
	.sel{
		.select{
			height:60px;
		}
	}
	
	.phone_sel_inp{
		.sel_inp_area{
			&:after{
				display:block;
				clear:both;
				content:'';
			}
			.sel_area,.phone_inp{
				float:left;
			}
			.sel_area{
				width:calc(32.65% - 3.265px);
			}
			.phone_inp{
				width:calc(67.34% - 6.734px);
				margin-left:10px;
			}
		}
		.sel_area{
			.select{
				&:after{
					right:20px;
				}
			}
		}
		.note_desc{
			position:relative;
			margin:12px 0 0 20px;
			padding-left:9px;
			font-size:12px;
			color:#dc0012;
			&:before{
				position:absolute;
				left:0;
				top:50%;
				width:3px;
				height:3px;
				margin-right:6px;
				background-color:#dc0012;
				content:'';
				transform:translateY(-50%);
			}
		}
	}
	.btn_area{
		margin-top:40px;
		text-align:center;
		.btn_next{
			width:240px;
			height:60px;
			border-radius:60px;
			font-size:18px;
			@include btn-border(red);
			&:disabled{
				border:0;
				background-color:#ddd;
				color:#fff;
				font-weight:800;
				pointer-events:none;
				cursor:default;
			}
		}			
	}
}

.experience_complete{
	.tab_cont_area{
		.application{
			border:0;
			.thumb{
				display:block;
				width:360px;
				margin:0 auto;
			}
			.txt_box{
				display:block;
				margin:48px 0 0 0;
				text-align:center;
			}
		}
			
		.btn_area{
			margin-top:30px;
			.btn_link{
				@include btn-border(red);
				display:inline-table;
				width:230px;
				height:60px;
				padding-top:18px;
				border-radius:60px;
				font-size:18px;
				font-weight:800;
				line-height:22px;
			}
		}
	}
}

@media screen and (max-width:1279px) {
	.experience_wrap{
		.accept{
			padding-top:0;
			margin-top:70px;
			border:0;
			.checkbox_area{
				.lab_chx{
					font-size:14px;
					line-height:1.29;
				}
			}
			.tit{
				text-align:center;
			}
			& + .btn_area{
				text-align:center;
				.btn_back{
					display:none;
				}
				.btn_link{
					width:176px;
					height:50px;
					margin-left:0;
				}
			}
		} 
		
		.tab_tit{
			.tit_lst{
				padding:0 22px;
			}
		}
		
		.tab_cont_area{
			.application{
				.thumb,.txt_box{
					display:block;
				}
				.thumb{
					margin:0 auto;
				}
				.txt_box{
					margin:30px 0 0 0;
					text-align:center;
				}
			}
			.txt_box{
				.sub_tit{
					font-size:18px;
					line-height:1.33;
					font-weight:300;
					br{
						display:block;
					}
				}
				.desc{
					display:none;
				}
			}
			.application{
				width:calc(100% - 60px);
				margin:0 auto;
			}
		}
		
		.step_inner{
			padding:0 30px;
			.step_tit{
				margin:0 auto;
			}
			.step_desc{
				margin-top:11px;
				text-align:center;
				font-size:22px;
				line-height:1.36;
			}
		}
		
		.step_form{
			margin-top:43px;
			.txt{
				margin-top:17px;
			}
			.inp_area{
				.inp{
					height:50px;
				}
			}
		}
		.sel{
			.select{
				height:50px;
				font-size:14px;
				&:after{
					right:21px;
					width:32px;
					height:32px;
					background-size:12px 6px;
				}
			}
		}
		
		.phone_sel_inp{
			.sel_area{
				.select{
					&:after{
						right:8%;
					}
				}
			} 
			.note_desc{
				margin:12px 0 0 11px;
			}
			.sel_inp_area{
				.phone_inp{
					margin-left:8px;
					width:calc(63.69% - 5.0958px);
				}
				.sel_area{
					width:calc(36.301% - 2.9041px);
					.middle{
						padding-right:10px;
					}
				}
			}
		} 
		.step1_form{
			.btn_area{
				margin-top:25px;
				.btn_next{
					width:176px;
					height:50px;
				}
			}
		}
	}
	.experience_complete{
		.tab_cont_area{
			.txt_box{
				.tit{
					line-height:1.2;
					font-weight:500;
				}
				.desc{
					display:block;
				}
			}
			.application{
				.thumb{
					width:220px;
				}
			}
			.btn_area{
				margin-top:30px;
				.btn_link{
					width:210px;
					height:50px;
				}
			}
		}
	}
} 
 $btn: btn;

.#{$btn}{
    position:relative;
    font-weight:bold;
    text-align:center;
    span{
        position:relative;
        z-index:1;
    }
    &.disabled{
        cursor:default;
        pointer-events:none;
    }
}
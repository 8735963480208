$screen-xsm: 320px; // Mobile Minimum Size
$screen-sm: 640px; // Tablet
$screen-lg: 1280px; // Desktop Minimum Size
$screen-xlg: 1920px; // Desktop Maximum Size

/* color */
$red:#dc0012;
$black:#1a1311;
$pink:#fcd8da;
$light_pink:#fceaeb;
$white:#fff;
$light_gray:#f4f4f4;
$gray:#dddddd;
$gray2:#707070;


/* font */
$defaultFontFamily: 'NanumSquareRound';
$pointFontFamily: 'SDIyagi';
$engPointFontFamily: 'BreeSerif';


/* z-index */
$z-indexes: (
    default: 10,
    dropdown: 20,
    header: 50,
    alert: 90,
    btn: 90,
    layer: 100,
    max: 1000
);